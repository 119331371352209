import { DataNode } from 'antd/lib/tree';
import React from 'react';

import { CrewBrand, CrewCategory, CrewProduct, CrewProps, DateRangeDayPeriod, Entity } from '.';
import { utils } from '../../../helpers';
import { SearchDataType } from '../../../hooks/useSearchDebounce';
import { Audience, EntryData, LoaderState, Store } from '@/legacy-types';
import { GoalsHelpers } from '../helpers';
import { CrewListResponse } from '../../../api/crew';
import { CrewAudience, CrewAudienceWithStats } from './CrewAudienceTypes';

export const CrewGoalRewardTypes = ['cash'] as const;
export type CrewGoalRewardType = (typeof CrewGoalRewardTypes)[number];
export type CrewGoalRewards = { [key: string]: CrewGoalReward };

// Fields both RetailCrewGoal and BrandCrewGoal share
type BaseGoal = {
	id: string;
	name: string;
	internalName?: string;
	description?: string;
	winCondition?: CrewGoalCondition;
	// Metric and MetricThreshold are used to determine winners.
	// For example Metric could be "unitsSold" and MetricThreshold
	// could be 100.
	metric?: CrewGoalMetric;
	metricThreshold?: number;
	// MetricPeriod is used to determine the time period over which
	// the metric is calculated. For example, if MetricPeriod is "day"
	// the goal will be reached if MetricThreshold is reached within any single
	// day during the goal period. If MetricPeriod is empty, the metric
	// will be calculated over the entire goal period.
	metricPeriod?: RewardValuePeriod; // The period of time over which the goal is measured. (Empty if goal is not measured over a period of time)
	rewards: CrewGoalRewards;

	audienceIDs: string[]; // Audience IDs

	includeProducts?: string[]; // Product IDs
	excludeProducts?: string[]; // Product IDs

	includeCategories?: string[]; // Category IDs
	excludeCategories?: string[]; // Category IDs

	startDate: number;
	endDate: number;
	createdAt?: number;
	updatedAt?: number;
	archived?: boolean;

	// maxWinners?: number

	clonedFrom?: string;
};

// Crew Goal is returned from the API when requesting a list of goals
export type RetailCrewGoal = BaseGoal & {
	locations: number[]; // Location IDs

	includeBrands?: string[]; // Brand IDs
	excludeBrands?: string[]; // Brand IDs

	eligibleMemberIDs?: string[]; // Employee IDs
	eligibleMemberCalculatedAt?: number;

	brandSponsorUID?: string;
	brandGoal?: boolean; // Id of the brands goal

	isActive?: boolean;
	settled?: boolean;
};

export type BrandCrewGoal = BaseGoal & {
	eligibleRetailers: { [uid: string]: number[] };
	optedInRetailers: { [uid: string]: string };
	optedOutRetailers: { [uid: string]: string };
	advertised?: boolean;
};

export type CrewGoal = RetailCrewGoal | BrandCrewGoal | SponsoredCrewGoal;

export type SponsoredCrewGoal = RetailCrewGoal & {
	sponsorUID: string;
	audiences?: CrewAudience[];
};

export interface CrewGoalGroup {
	id: string;
	name: string;
	retailerGoals: { [key: string]: boolean };
	brandGoals: { [key: string]: boolean };
	created: number;
	updated: number;
	archived: boolean;
}

export interface CrewQualification {
	minRevenue?: number; // Minimum revenue an employee must achieve before qualifying for a top reward.
	minUnits?: number; // Minimum number of units per transactions an employee must achieve before qualifying for a top reward.
	minOrders?: number; // Minium number of orders an employee must achieve before qualifying for a top reward.
}

export interface CrewGoalReward {
	place?: number; // The place the employee must achieve to win the reward. (Empty if reward is for all participants)
	name?: string;
	rewardType: CrewGoalRewardType;
	value?: number; // The value of the dependent value, IE, "for every <value> units sold..." earn rewardValue

	// VariableValue and VariableAmount are used to calculate the reward value
	// For example, if the reward is $1 per 10 unit sold, VariableValue would be
	// 1 and VariableAmount would be 10.
	variableValue?: number;
	variableAmount?: number;

	fulfilledByAIQ?: boolean;
}

export const ValuePeriods = {
	day: 'Day',
	// 'week': 'Week',
	// 'month': 'Month',
	// 'quarter': 'Quarter',
	// 'year': 'Year'
} as const;

export type RewardValuePeriod = keyof typeof ValuePeriods;

export const CrewGoalMetrics = {
	// "percentIncrease": {
	//     title: "% Increase",
	//     description: "Increase sales of selected products",
	// },
	unitsSold: {
		title: 'Units Sold',
		description: 'Track sales of selected products in units',
		shown(goal: CrewGoal, vv: GoalViewingVersion) {
			return true;
		},
	},
	dollarAmount: {
		title: 'Dollar Amount',
		description: 'Track sales of selected products in dollars',
		shown(goal: CrewGoal, vv: GoalViewingVersion) {
			return true;
		},
	},
	transactions: {
		title: 'Transactions',
		description: 'Track number of transactions made',
		shown(goal: CrewGoal, vv: GoalViewingVersion) {
			// Can't be seen for brand goals
			if (vv === 'brand') return false;
			return true;
		},
	},
} as const;
export type CrewGoalMetric = keyof typeof CrewGoalMetrics;

export const CrewGoalWinConditions = {
	allCanWin: {
		shortTitle: 'All can win',
		title: 'All participants can win',
		description: 'Employees across all selected locations will compete on a single goal. Each eligible participant who meets the goal wins.',
		shown(goal: CrewGoal, _: GoalViewingVersion) {
			return true;
		},
		apply(goal: CrewGoal, _: GoalViewingVersion): CrewGoal {
			return {
				...goal,
				winCondition: 'allCanWin',
			};
		},
	},
	setNumber: {
		shortTitle: 'Set number',
		title: 'Set number of winners',
		description: 'Employees across all selected locations will compete on a single leaderboard. Only ONE set of winners.',
		shown(goal: CrewGoal, _: GoalViewingVersion) {
			return true;
		},
		apply(goal: CrewGoal, _: GoalViewingVersion): CrewGoal {
			return {
				...goal,
				winCondition: 'setNumber',
				metricPeriod: undefined,
			};
		},
	},
	locationGoal: {
		shortTitle: 'Each location',
		title: 'Goal for each location',
		description: 'A leaderboard is created for each selected location. Each location has its own set of winners.',
		shown(goal: CrewGoal, _: GoalViewingVersion) {
			return true;
		},
		apply(goal: CrewGoal, _: GoalViewingVersion): CrewGoal {
			return {
				...goal,
				winCondition: 'locationGoal',
				metricPeriod: undefined,
			};
		},
	},
	teamGoal: {
		shortTitle: 'Team goal',
		title: 'Team goal',
		description: 'Everyone competes together to hit the goal and everyone wins together.',
		shown(goal: CrewGoal, _: GoalViewingVersion) {
			return true;
		},
		apply(goal: CrewGoal, _: GoalViewingVersion): CrewGoal {
			return {
				...goal,
				winCondition: 'teamGoal',
				metricPeriod: undefined,
			};
		},
	},
} as const;

export type CrewGoalCondition = keyof typeof CrewGoalWinConditions;

export interface CrewGoalsPageProps extends CrewProps {
	searchData: SearchDataType;
	loader: LoaderState;
	setLoader: React.Dispatch<React.SetStateAction<LoaderState>>;
	goalData: EntryData<RetailCrewGoal | BrandCrewGoal>;
	setGoalData: React.Dispatch<React.SetStateAction<EntryData<RetailCrewGoal | BrandCrewGoal>>>;
	groupData: EntryData<CrewGoalGroup>;
	setGroupData: React.Dispatch<React.SetStateAction<EntryData<CrewGoalGroup>>>;
	audiences: EntryData<Partial<CrewAudienceWithStats>>;
	setAudiences: React.Dispatch<React.SetStateAction<EntryData<Partial<CrewAudienceWithStats>>>>;
	storeLocations: EntryData<Store>;
	setStoreLocations: React.Dispatch<React.SetStateAction<EntryData<Store>>>;
	handleSubmit: () => void;
	archiveGroup: (group: Partial<CrewGoalGroup>) => void;
	selectedGroup: string | undefined;
	setSelectedGroup: React.Dispatch<React.SetStateAction<string | undefined>>;
	editingGroup: string | undefined;
	setEditingGroup: React.Dispatch<React.SetStateAction<string | undefined>>;
	filters: CrewGoalsFilters;
	setFilters: React.Dispatch<React.SetStateAction<CrewGoalsFilters>>;
	goal: Partial<RetailCrewGoal> | undefined;
	setGoal: React.Dispatch<React.SetStateAction<Partial<RetailCrewGoal> | undefined>>;
	originalGoal: Partial<RetailCrewGoal> | undefined;
	setOriginalGoal: React.Dispatch<React.SetStateAction<Partial<RetailCrewGoal> | undefined>>;
	stats: GoalsPageStats;
	setStats: React.Dispatch<React.SetStateAction<GoalsPageStats>>;
	viewingVersion: GoalViewingVersion;
	setViewingVersion: React.Dispatch<React.SetStateAction<GoalViewingVersion>>;
}

export type GoalsPageStats = {
	archived: number;
	active: number;
};

export interface FormattedGoalGroup extends CrewGoalGroup, DataNode {
	isOfficial?: boolean; // For official groups
	childrenNodes?: string[];
	childCount?: number;
}

export interface CrewGoalsFilters {
	// period: DateRangeDayPeriod;
	// dateRange: number[];
	// locations: string[];
	// products: string[];
	// categories: string[];
	// brands: string[];
	// types: CrewGoalMetric[];
	showArchivedGoals?: boolean;
	showArchivedGroups: boolean;
}

export const GoalViewingVersions = {
	'retailer': {
		title: () => 'My Goals',
		description: 'View and manage your goals',
		show: () => utils.isRetail(),
	},
	'brand': {
		title: () => (utils.isRetail() ? 'Brand Goals' : 'My Goals'),
		description: 'View and manage brand goals',
		show: () => utils.isBrand(),
	},
	'brand-sponsored': {
		title: () => 'Sponsored Goals',
		description: 'View and manage brand sponsored goals',
		show: () => utils.isRetail(),
	},
} as const;
export type GoalViewingVersion = keyof typeof GoalViewingVersions;

// --------------------------------------------------

export const GoalDrawerTabDetails = {
	'details': {
		title: 'Details',
		canContinue: (goal: Partial<RetailCrewGoal | BrandCrewGoal>, isBrandGoal?: boolean): boolean => {
			return !!goal?.id || (!!goal?.name && !!goal.startDate && !!goal.endDate && goal.startDate < goal.endDate);
		},
	},
	'targets': {
		title: 'Participants',
		canContinue: (goal: Partial<RetailCrewGoal | BrandCrewGoal>, isBrandGoal?: boolean): boolean => {
			const retailGoal = goal as RetailCrewGoal;
			// const hasLocations = (retailGoal?.locations?.length || 0) > 0;
			const hasAudiences = (goal?.audienceIDs?.length || 0) > 0;

			if (isBrandGoal) {
				const brandGoal = goal as BrandCrewGoal;
				const targetStoresLen = Object.values(brandGoal.eligibleRetailers || {}).reduce((acc, val) => acc + (val ?? []).length ?? 0, 0);
				return !!goal.winCondition && targetStoresLen !== 0;
			}
			// return !!goal.winCondition && (hasLocations || hasAudiences);
			return !!goal.winCondition;
		},
	},
	'goal': {
		title: 'Goal',
		canContinue: (goal: Partial<RetailCrewGoal | BrandCrewGoal>, isBrandGoal?: boolean): boolean => {
			const includedProducts = (goal?.includeProducts?.length || 0) > 0;
			const excludedProducts = (goal?.excludeProducts?.length || 0) > 0;
			const includedCategories = (goal?.includeCategories?.length || 0) > 0;
			const excludedCategories = (goal?.excludeCategories?.length || 0) > 0;

			const hasFilters = includedProducts || excludedProducts || includedCategories || excludedCategories;
			const hasMetric = !!goal.metric;

			if (isBrandGoal) {
				return hasMetric && hasFilters;
			}

			const retailGoal = goal as RetailCrewGoal;

			const includedBrands = (retailGoal?.includeBrands?.length || 0) > 0;
			const excludedBrands = (retailGoal?.excludeBrands?.length || 0) > 0;

			const hasBrands = includedBrands || excludedBrands;

			return hasMetric && (hasFilters || hasBrands);
		},
	},
	'rewards': {
		title: 'Rewards',
		canContinue: (goal: Partial<RetailCrewGoal | BrandCrewGoal>, isBrandGoal?: boolean): boolean => {
			return (Object.keys(goal?.rewards || {})?.length || 0) > 0;
		},
	},
	'review-publish': {
		title: 'Review & Publish',
		canContinue: (goal: Partial<RetailCrewGoal | BrandCrewGoal>, isBrandGoal?: boolean): boolean => {
			return GoalsHelpers.validateGoal(goal)?.valid;
		},
	},
} as const;

export type GoalDrawerTab = keyof typeof GoalDrawerTabDetails;

export interface CrewGoalsDrawerTabProps extends CrewGoalsPageProps {
	external?: boolean;
	isDisabled?: boolean;

	selectedTab: GoalDrawerTab;
	setSelectedTab: React.Dispatch<React.SetStateAction<GoalDrawerTab>>;

	nextTab: (tab: GoalDrawerTab) => void;

	completed: GoalDrawerTab[];
	setCompleted: React.Dispatch<React.SetStateAction<GoalDrawerTab[]>>;

	handleClose: (actionCheck?: boolean) => void;

	loadProductData: (search?: string) => void;
	productData: CrewGoalBuilderEntity<CrewProduct>;
	setProductData: React.Dispatch<React.SetStateAction<CrewGoalBuilderEntity<CrewProduct>>>;

	loadBrandData: (search?: string) => void;
	brandData: CrewGoalBuilderEntity<CrewBrand>;
	setBrandData: React.Dispatch<React.SetStateAction<CrewGoalBuilderEntity<CrewBrand>>>;

	loadCategoryData: (search?: string) => void;
	categoryData: CrewGoalBuilderEntity<CrewCategory>;
	setCategoryData: React.Dispatch<React.SetStateAction<CrewGoalBuilderEntity<CrewCategory>>>;
}

export type CrewGoalBuilderEntity<T> = CrewListResponse<T> & {
	loading?: boolean;
	mapped: { [key: string]: T };
};

export type ProductEntry = {
	id: number | string;
	brandID?: number | string;
	name?: string;
	brand?: string;
	category: string;
};

export type AvailableGoalPreview = {
	id: string;
	sponsorUID: string;
	goal: RetailCrewGoal;
	audiences: Audience[];
};

export type AvailableBrandGoal = {
	brandUID: string;
	brandGoalID: string;
	optedIn: boolean;
	optedInAt: number;
	optedOut: boolean;
	optedOutAt: number;
	createdAt: number;
};
