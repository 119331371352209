import { CrewGoalCondition, CrewGoalWinConditions, CrewGoalMetric, CrewGoalMetrics, CrewGoalReward, RetailCrewGoal } from '../../crew-types';
import { BrandCrewGoal, CrewGoal, CrewGoalRewards, GoalViewingVersion } from '../../crew-types/CrewGoalTypes';

export function getDefaultCrewGoal<T>(type: GoalViewingVersion): T {
    const base: Partial<RetailCrewGoal & BrandCrewGoal> = {
        winCondition: 'allCanWin',
        metric: 'unitsSold'
    }
    if (type === 'retailer') {
        return {
            ...base,
        } as RetailCrewGoal as T
    }
    return {
        ...base,
    } as BrandCrewGoal as T
}

function validateGoal(goal: Partial<RetailCrewGoal | undefined>): { valid: boolean, errors: string[] } {
    const goalMetrics = Object.keys(CrewGoalMetrics) as CrewGoalMetric[]
    const winConditions = Object.keys(CrewGoalWinConditions) as CrewGoalCondition[]
    if (!goal) return { valid: false, errors: ["Goal is required"] }
    const errors: string[] = []
    if (!goal.name) errors.push("Goal name is required")
    if (!goal.metric) errors.push("Goal type is required")
    if (goal.metric && !goalMetrics.includes(goal?.metric)) errors.push("Invalid goal metric")
    if (!goal.winCondition) errors.push("Win condition is required")
    if (goal.winCondition && !winConditions.includes(goal?.winCondition)) errors.push("Invalid win condition")
    if (((Object.values(goal?.rewards || {})?.length) || 0) === 0) errors.push("At least one reward is required")

    if (goal.winCondition === 'locationGoal') {
        if ((goal.locations?.length || 0) === 0) errors.push("At least one location is required when using location goal")
    }

    if (goal.winCondition === 'setNumber') {
        // if ((goal.maxWinners || 0) <= 0) errors.push("Set number must be greater than 0")
        if ((Object.keys(goal.rewards ?? {})?.length || 0) === 0) errors.push("At least one reward is required when using set number goal")
    }

    if (goal.startDate && goal.endDate && goal.startDate >= goal.endDate) errors.push("Start date must be before end date")

    if (goal.metricPeriod && goal.metricPeriod !== 'day') errors.push("Invalid metric period")

    if ((goal.includeProducts?.length || 0) > 0 && (goal.excludeProducts?.length || 0) > 0) {
        errors.push("Cannot use both include and exclude product filters")
    }

    const requireThreshold = goal.winCondition === 'teamGoal' || goal.winCondition === 'allCanWin'
    if (requireThreshold && (goal.metricThreshold ?? 0) <= 0) {
        errors.push("Threshold must be greater than 0")
    }

    errors.push(...validateWinConditions(goal))

    for (const reward of Object.values(goal?.rewards || {})) {
        errors.push(...validateReward(reward))
    }

    return { valid: !errors.length, errors }
}

function validateWinConditions(goal: Partial<RetailCrewGoal>, partial = false): string[] {
    const errors: string[] = []
    switch (goal.winCondition) {
        case "allCanWin":
            errors.push(...validateWinConditionAll(goal, partial))
            break;
        case "teamGoal":
            errors.push(...validateWinConditionTeam(goal, partial))
            break;
        case "setNumber":
            errors.push(...validateWinConditionSetNumber(goal))
            break;
        case "locationGoal":
            errors.push(...validateWinConditionLocation(goal))
            break;
    }
    return errors
}

function validateReward(reward: Partial<CrewGoalReward>): string[] {
    const errors: string[] = []
    if (!reward.name) errors.push("Reward name is required")
    if ((reward.place || 0) < 0) errors.push("Place must not be negative")
    if ((reward.value || 0) > 0) {
        // flat rate reward
        if ((reward.variableValue || 0) > 0 || (reward.variableAmount || 0) > 0) {
            errors.push("Flat rate rewards cannot have variable values")
        }
    } else {
        // variable rate reward
        if ((reward.variableValue || 0) <= 0 || (reward.variableAmount || 0) <= 0) {
            errors.push("Variable rate rewards must have variable values")
        }
    }
    return errors
}


function validateWinConditionAll(goal: Partial<RetailCrewGoal>, partial: boolean): string[] {
    const errors: string[] = []
    const rewardLen = Object.values(goal?.rewards || {}).length || 0;
    const validLen = partial ? rewardLen <= 1 : rewardLen === 1
    if (!validLen) errors.push("All can win can only have one reward")
    return errors
}

function validateWinConditionTeam(goal: Partial<RetailCrewGoal>, partial: boolean): string[] {
    const errors: string[] = []
    const rewardLen = Object.values(goal?.rewards || {}).length || 0;
    const validLen = partial ? rewardLen <= 1 : rewardLen === 1
    if (!validLen) errors.push("Win condition team can only have one reward")
    if (goal.metricPeriod) errors.push("Win condition team cannot have a metric period")
    return errors
}

function validateWinConditionSetNumber(goal: Partial<RetailCrewGoal>): string[] {
    const errors: string[] = []
    if (goal.metricPeriod) errors.push("Win condition team cannot have a metric period")
    for (const reward of Object.values(goal?.rewards || {})) {
        if ((reward.place || 0) < 1) errors.push("Rewards must have a place")
    }
    return errors
}

function validateWinConditionLocation(goal: Partial<RetailCrewGoal>): string[] {
    const errors: string[] = []
    if (goal.metricPeriod) errors.push("Win condition location cannot have a metric period")
    for (const reward of Object.values(goal?.rewards || {})) {
        if ((reward.place || 0) < 1) errors.push("Rewards must have a place")
    }
    return errors
}

const defaultReward = (place?: number): CrewGoalReward => ({ rewardType: "cash", place: place || 1, });
const cleanRewards = (rewards: CrewGoalRewards, init?: boolean): CrewGoalRewards => {
    return Object.entries(rewards).reduce((acc, [key, reward]) => {
        reward.place = parseInt(key + 1);
        if (init && (reward.variableAmount === 0 && reward.variableValue === 0)) {
            reward.variableAmount = undefined;
            reward.variableValue = undefined;
        }
        return { ...acc, [key]: reward };
    }, {});
};



const cleanGoal = (goal: Partial<CrewGoal>, viewingVersion: GoalViewingVersion): Partial<CrewGoal> => {
    if (viewingVersion === 'retailer') {
        const retailGoal: Partial<RetailCrewGoal> = goal as RetailCrewGoal;
        if (retailGoal.locations?.includes(-1)) {
            retailGoal.locations = [];
        }
        return retailGoal;
    }

    if (viewingVersion === 'brand') {
        const brandGoal: Partial<BrandCrewGoal> = goal as BrandCrewGoal;
        return brandGoal;

    }
    return goal;
}

const onSelectGoal = (goal: Partial<CrewGoal> | undefined, viewingVersion: GoalViewingVersion): Partial<CrewGoal> | undefined => {
    if (goal?.id) {
        if (viewingVersion === 'retailer') {
            const retailGoal: Partial<RetailCrewGoal> = goal as RetailCrewGoal;
            if (retailGoal.locations?.length === 0) {
                retailGoal.locations = [-1];
            }
            return retailGoal;
        }

        if (viewingVersion === 'brand') {
            const brandGoal: Partial<BrandCrewGoal> = goal as BrandCrewGoal;
            return brandGoal;
        }
    }
    return goal;

}

export const GoalsHelpers = {
    cleanGoal,
    validateGoal,
    onSelectGoal,
    validateWinConditions,
    validateReward,
    defaultReward,
    cleanRewards
}   