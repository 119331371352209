import React from 'react';
import { utils } from '../../../helpers';
import { SearchDataType } from '../../../hooks/useSearchDebounce';
import { EntryMap } from '@/legacy-types';
import { DateRangeDayPeriod } from './CrewLeaderboardTypes';

export const PayoutViewingVersions = {
	retailer: {
		title: () => 'My Payouts',
		description: 'View and manage your goals',
		show: () => utils.isRetail(),
	},
	brand: {
		title: () => (utils.isRetail() ? 'Brand Payouts' : 'My Payouts'),
		description: 'View and manage brand goals',
		show: () => utils.isBrand(),
	},
	// 'brand-sponsored': {
	//     title: () => 'Sponsored Goals',
	//     description: 'View and manage brand sponsored goals',
	//     show: () => utils.isRetail(),
	// },
} as const;
export type PayoutViewingVersion = keyof typeof PayoutViewingVersions;

export type PayoutsLoader = { [key in PayoutViewingVersion]: boolean };

export const CrewPayoutStatusConfig = {
	pending: {
		label: 'Pending',
	},
	upcoming: {
		label: 'Upcoming',
	},
	completed: {
		label: 'Completed',
	},
	disputed: {
		label: 'Disputed',
	},
} as const;

export type CrewPayoutStatus = keyof typeof CrewPayoutStatusConfig;

export type RewardStage = 'unsent' | 'sent' | 'verified';
export interface CrewPayout {
	id: string;
	uid: string; // User ID of the user who created the payout
	// LiableUID is the UID responsible for approving and fulfilling the payout.
	// Normally this is the same as the UID, but for sponsored goals, this is the UID of the brand sponsor.
	liableUID?: string;

	goalID: string; // Goal ID which the payout is from/attached to
	memberID: string; // Crew member ID which the payout is from/attached to
	goalName: string;
	memberName: string;

	value: number; // Cost of the payout
	rewardType: string; // Type of reward for the payout

	approved: boolean; // If the payout was approved
	approvedByEmail: string; // Email of the user who approved the payout
	settled: boolean; // If the payout was settled
	createdAt: number; // Date the payout was created
	approvedAt: number; // Date the payout was approved
	approvesAt?: number; // ! Date to be approved, set within the dashboard
	settledAt: number; // Date the payout was settled

	idempotencyKey: string; // Idempotency key for the payout
	rewardStage: RewardStage | ''; // Stage of the reward for the payout
	rewardSendError: string; // Error message if the reward failed to send
	rewardVerifiedSentAt: number; // Date the reward was verified as sent
	lastUpdate: number; // Date the payout was last updated

	rewardReciept: RewardReceipt;
}

export interface BrandPayoutInfo {
	id: string;
	brandGoalID: string;
	retailerUID: string;
	retailerPayoutID: string;
	approved: boolean;
	approvedByEmail: string;
	approvedAt: number;
	createdAt: number;
}

export type BrandPayout = {
	brandPayout: BrandPayoutWithNames;
	retailerPayout: CrewPayout;
};

export type BrandPayoutWithNames = BrandPayoutInfo & {
	goalName: string;
	memberName: string;
};

export type BrandFormattedForUXPayout = CrewPayout & {
	brandPayout: BrandPayoutWithNames;
};

export interface RewardReceipt {
	id: string; // from the RewardProvider on the Tremendous side
	subtotal: number; // amount given as reward
	fees: number; // fees charged by the award provider
	status: string;
}

export interface CrewPayoutFilters {
	period: DateRangeDayPeriod;
	status: CrewPayoutStatus | undefined;
	dateRange: number[];
}

export interface CrewPayoutsSubProps {
	viewingVersion: PayoutViewingVersion;
	setViewingVersion: React.Dispatch<React.SetStateAction<PayoutViewingVersion>>;

	searchData: SearchDataType;

	filters: CrewPayoutFilters;
	setFilters: React.Dispatch<React.SetStateAction<CrewPayoutFilters>>;

	retailPayouts: EntryMap<CrewPayout>;
	setRetailPayouts: React.Dispatch<React.SetStateAction<EntryMap<CrewPayout>>>;

	brandPayouts: EntryMap<BrandFormattedForUXPayout>;
	setBrandPayouts: React.Dispatch<React.SetStateAction<EntryMap<BrandFormattedForUXPayout>>>;
}
