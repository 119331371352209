import { formatHLCTotals } from './../accountHealth/utils';
import { API, asyncWrap } from '../../api';
import { UserAlertUpdateActionInput } from '../../api/userAlerts';
import timeUtils from '../../helpers/timeUtils';
import utils from '../../helpers/utils';
import { AcknowledgedAlert, Acknowledgement, AlertsContext, AlertsContextState, AlertStore, EntryMap, Role, UserAlert } from '@/legacy-types';

export const isStillSnoozed = (alert: UserAlert, uid: string, email: string, acknowledge?: Acknowledgement | false) => {
	if (!acknowledge && acknowledge !== false) acknowledge = getAcknowledgement(alert, uid, email);
	if (!acknowledge) return false;
	if (!acknowledge.snoozeDuration || !acknowledge.snoozeStart) return false;
	const snoozeEnd = (acknowledge.snoozeStart + acknowledge.snoozeDuration) * 1000;
	return snoozeEnd > Date.now();
};

export const getUnViewedAlerts = (alerts: UserAlert[], uid: string, email: string) => {
	return (alerts || []).filter((alert: UserAlert) => isUnViewed(alert, uid, email));
};

export const isUnViewed = (alert: UserAlert, uid: string, email: string) => {
	const acknowledged = getAcknowledgement(alert, uid, email);
	if (isStillSnoozed(alert, uid, email, acknowledged)) return false;
	return !acknowledged && !alert.adminViews?.includes(email);
};

export const getUnAcknowledgedAlerts = (alerts: UserAlert[], uid: string, email: string) => {
	return (alerts || []).filter((alert: UserAlert) => {
		const acknowledged = getAcknowledgement(alert, uid, email);
		if (isStillSnoozed(alert, uid, email, acknowledged)) return false;
		return !acknowledged || acknowledged.acknowledged !== true;
	});
};

export const getAcknowledgement = (alert: UserAlert, uid: string, email: string): Acknowledgement | undefined => {
	return alert?.acknowledged?.[uid]?.[email];
};

// check if any alerts require attention
export const shouldDrawerAutoOpen = (alerts: UserAlert[]): boolean => {
	if (!alerts || !alerts.length) return false;
	return alerts.some((alert) => shouldBeShown(alert));
};

// check if alert requires attention
export const shouldBeShown = (alert: UserAlert, uid: string = utils.uid, email: string = utils.auth.getEmail(), role: Role = utils.auth.getRole()): boolean => {
	if (!alert) return false;
	// if the alert is not active, return false
	if (!alert.active) return false;
	// check if alert is archived
	if (alert.archived) return false;
	// check alert is within start and end sending range
	const now = Math.floor(Date.now() / 1000);
	if ((alert.startTime && alert.startTime > now) || (alert.endTime && alert.endTime < now)) return false;
	// check if user should see it
	if (alert.targetIDs && !alert.targetIDs.includes(uid)) return false;
	// check email should see it
	if (alert.targetEmails && !alert.targetEmails.includes(email)) return false;
	// check role should see it
	if (alert.permissionLevels && !alert.permissionLevels.includes(role)) return false;
	// check if alert is acknowledged
	if (alert.required && isAcknowledged(alert, uid, email)) return false;
	// check if alert is snoozed
	if (!alert.disAllowSnooze && isSnoozed(alert, uid, email)) return false;
	return true;
};

// check if alert is acknowledged for email
export const isAcknowledged = (alert: UserAlert, uid: string, email: string): boolean => {
	const ackMap = alert.acknowledged || ({} as AcknowledgedAlert);
	// check admins as they only need to view once per uid always
	if (alert.adminViews && alert.adminViews.includes(email)) return true;
	// if the alert is view only once, and the user has already viewed it, return true
	if (alert.viewOnlyOnce && !!Object.keys(ackMap[uid] || {}).length) return true;
	// if if alert is not view once check sub user acknowledge
	if (ackMap && ackMap[uid] && ackMap[uid][email]) return !!ackMap[uid][email].acknowledged;
	return false;
};
// check if alert is snoozed for email
export const isSnoozed = (alert: UserAlert, uid: string, email: string): boolean => {
	const ackMap = alert.acknowledged || ({} as AcknowledgedAlert);
	// check if email exist user's in map
	if (ackMap && ackMap[uid] && ackMap[uid][email]) {
		const snoozeStart = ackMap[uid][email].snoozeStart;
		const snoozeDuration = ackMap[uid][email].snoozeDuration;
		// check if snooze start and duration exist
		if (snoozeStart && snoozeDuration) {
			const now = Math.floor(Date.now() / 1000);
			const snoozeEnd = snoozeStart + snoozeDuration;
			if (now >= snoozeStart && now <= snoozeEnd) return true;
		}
	}
	return false;
};

export const validateAlert = (alert: UserAlert) => {
	const errors: string[] = [];
	if (alert.manuallyCreated && !alert.internalName) errors.push('Alert must have a(n) internal name for identifying it');
	if (!alert.text) errors.push('Alert must have text');

	if (alert.active) {
		if (alert.required) errors.push('Alert cannot be required');
	}

	return errors;
};

function updateAnalyticsAlertsTimestamps(alert: UserAlert): UserAlert {
	return alert;
	// if (!alert.prefix?.value) return alert;
	// const regex = /\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}/g;
	// const matches = (alert.prefix.value.match(regex) || []);
	// if (!matches) {
	// 	return alert;
	// }
	// const [ startDate, endDate ] = matches;
	// alert.prefix.value = `Analytics Report Completed: ${timeUtils.formatTimestamp(startDate)}${endDate ? (' - ' + timeUtils.formatTimestamp(endDate)) : ''}`;
	// return alert;
}

function parseAndRemoveStrings(htmlString: string) {
	try {
		const regex =
			/<p>(.*?)<br\/><\/p>\n<p><a href="(.*?)">See Documentation<\/a><\/p>\n<details style="cursor: pointer">\n<summary>Raw Error<\/summary>\n(.*?)\n<\/details>/s;
		const matches = htmlString.match(regex);

		if (matches) {
			const [, errorMessage, documentationLink, rawError] = matches;
			const cleanedHTML = htmlString.replace(matches[0], '');

			return {
				errorMessage: errorMessage.trim(),
				documentationLink: documentationLink.trim(),
				rawError: rawError.trim(),
				cleanedHTML: cleanedHTML.trim(),
			};
		}
	} catch (e) {
		console.warn(e);
	}
	return null;
}

const ERROR_RETRIEVING_FROM_IMPORTER = 'errorretrievingfromimporter';
const FINAL_REMINDER_TO = 'Final Reminder to';
const ANALYTICS_REPORT_COMPLETED = 'Analytics Report Completed';
const DETAILS_STYLE = '<details style';

// Some legacy alerts require formatting or filtering before being shown
export const formatAlertsData = (userAlerts: UserAlert[], alertsMap: EntryMap<UserAlert>, noLayout?: boolean): Partial<AlertsContextState> => {
	if (!userAlerts || !userAlerts?.length) {
		return { alerts: {} };
	}

	const inputAlerts: UserAlert[] = Array.isArray(userAlerts) ? userAlerts : [userAlerts];

	const alerts = inputAlerts.reduce((acc, ua) => {
		if (isValidAlert(ua, noLayout)) {
			if (ua?.text?.value?.includes(DETAILS_STYLE)) {
				const cleaned = parseAndRemoveStrings(ua.text.value);
				if (cleaned) {
					ua.text.value = cleaned.errorMessage;
					ua.suffix = { link: cleaned.documentationLink, value: 'See documentation' };
					ua.hover = { value: cleaned.rawError };
				}
			}
			if (ua?.prefix?.value?.includes(ANALYTICS_REPORT_COMPLETED)) {
				const now = Math.floor(Date.now() / 1000);
				// if it's start date is over 24 hours ago, we don't want to show it
				if (ua.startTime && ua.startTime < now - 86400) return acc;
				ua = updateAnalyticsAlertsTimestamps(ua);
			}

			const newAlert: UserAlert = utils.clone(ua);

			if (newAlert.id === '28376') {
				acc[ua.id || ''] = {
					...newAlert,
					...Q1ALERT,
				};
				return acc;
			}

			acc[ua.id || ''] = newAlert;
		}
		return acc;
	}, alertsMap || {});
	// const entities = Object.keys(alerts).sort((a, b) => (alerts[b].updated || alerts[b].created || 0) - (alerts[a].updated || alerts[a].created || 0));
	return {
		alerts,
	};
};

export const sortUserAlerts = (a: UserAlert, b: UserAlert) => {
	if (a.created !== b.created) return (a.created || 0) > (b.created || 0) ? -1 : 1;
	if (a.updated !== b.updated) return (a.updated || 0) > (b.updated || 0) ? -1 : 1;
	return (a.id || 0) > (b.id || 0) ? -1 : 1;
};

const isImporterTypeError = (rawErr: string, hoverErr: string) => {
	return [rawErr, hoverErr].some((err) => utils.strify(err)?.includes(ERROR_RETRIEVING_FROM_IMPORTER)) || rawErr?.endsWith(' : :');
};

// Some alerts are old, invalid, or busted and should be removed from the list
const isValidAlert = (ua: UserAlert, noLayout?: boolean) => {
	// IF the user alert doesn't have any text, we don't want to show it
	if (!ua || (!ua.prefix?.value && !ua.text?.value && !ua.suffix?.value)) return false;
	// If this alert alert is not visible to the current user, we don't want to show it
	if (noLayout && !ua.permissionLevels?.includes('budtender' || utils.auth.getRole())) return false;
	let rawErr = ua?.text?.value?.trim() || '',
		hoverErr = ua?.hover?.value?.trim() || '';
	// Remove old final reminder alerts
	if (ua.type === 'recipe' && rawErr.startsWith(FINAL_REMINDER_TO)) return false;
	// If this alert is an importer error, and it doesn't have an end time, we don't want to show it
	if (isImporterTypeError(rawErr, hoverErr) && !ua?.endTime) return false;
	// If this alert is an importer error, and it has a start or end time, we don't want to show it if it's not in the current time range
	if (isImporterTypeError(rawErr, hoverErr) || ua.type === 'error') {
		const now = Math.floor(Date.now() / 1000);
		if ((ua.startTime && ua.startTime > now) || (ua.endTime && ua.endTime < now)) return false;
	}
	// If this alert is manually created, and it's not active, we don't want to show it
	if (ua.manuallyCreated && !ua.active) return false;
	return true;
};

// ! This function is used to fetch alerts for the user
export const fetchUserAlerts = (
	uid: string, // The user ID to fetch alerts for
	[alertData, setAlertData]: AlertsContext, // Alert context to update
	check = 10,
	noLayout?: boolean,
) => {
	// In some cases we don't need to check for alerts
	if (utils.uid === '1') return;

	asyncWrap(async () => {
		let alertsData: UserAlert[] = [];
		// when requesting alerts we only get new ones, so we need to add the old ones back in
		// const cachedAlerts = getCachedUserAlerts(uid, check);
		// if (cachedAlerts.length) {
		// alertsData = cachedAlerts;
		// } else {
		alertsData = await API.getUserAlerts({}); // showAcknowledged: true })
		// cacheUserAlerts(uid, alertsData);
		// }

		const formattedAlerts = formatAlertsData(alertsData, alertData.alerts || {}, noLayout);
		setAlertData({ ...formattedAlerts, updated: Date.now() });
	});
};

// update alert with an action
export const submitUserAlertAction = (
	[alertData, setAlertData]: AlertsContext, // Alert context to update
	target?: UserAlert | UserAlert[], // The alert(s) to apply the action to
	options?: Partial<UserAlertUpdateActionInput>, // The action to apply
	noLayout?: boolean, // Optionally is this for budtender view
) =>
	asyncWrap(async () => {
		if (!target) return;
		const currentEmail = utils.auth.getEmail();
		let query: UserAlert[] = (Array.isArray(target) ? target : [target]) as UserAlert[];

		const requiredAcknowledgement: string[] = (query || [])
			.filter((alert: UserAlert) => !!alert?.id && alert.required && getAcknowledgement(alert, utils.uid, currentEmail) === undefined)
			.map((alert: UserAlert) => `Alert: ${alert.prefix?.value || ''} is required!`);

		// If there are any required alerts that have not been acknowledged, we return an error
		if (requiredAcknowledgement.length > 0 && options?.action === 'snooze') {
			return utils.showErrs(requiredAcknowledgement, 'You must acknowledge required alerts!');
		}

		// The ID's to apply the action to
		const ids = (query || []).map((alert: UserAlert) => alert.id || '');

		// Send request to update to server
		const { alerts, errors } = (await API.updateUserAlertsClicked({ ids, ...options })) || [];
		// If we have any errors, show them
		if ((errors || []).length) {
			utils.showErrs(errors, 'Error updating alerts!');
		}

		const formattedAlerts = formatAlertsData(alerts, alertData.alerts || {}, noLayout);

		setAlertData((s) => ({ ...s, ...formattedAlerts, updated: Date.now() }));
		updateCachedAlerts(utils.uid, Object.values(alerts || {}));
	});

export const getCachedUserAlerts = (uid: string, check?: number): UserAlert[] => {
	const storedValue: AlertStore = (utils.local.getLocal('new_dash_alerts', {}) || {})?.[uid] || {};
	const updatedInLast10Min = Date.now() - (storedValue.updated || 0) < timeUtils.units.MINUTE_MS * (check || 1);
	deleteOldCache();
	if (storedValue.updated && updatedInLast10Min) {
		return utils.decodeObject(storedValue.data || '') || [];
	}
	return [];
};

export const updateCachedAlerts = (uid: string, alerts: UserAlert[]) => {
	const alertMap = alerts.reduce((acc, alert) => ({ ...acc, [alert?.id || '']: alert }), {} as EntryMap<UserAlert>);
	const storedValue = getCachedUserAlerts(uid).map((alert) => alertMap[alert.id || ''] || alert);
	cacheUserAlerts(uid, storedValue);
};

export const cacheUserAlerts = (uid: string, alerts: UserAlert[]) => {
	const isMobile = window.innerWidth < 768;
	utils.local.setLocal('new_dash_alerts', {
		[uid]: {
			updated: Date.now(),
			data: utils.encodeObject((alerts || [])?.slice(0, isMobile ? 10 : 100)) || '',
		},
	});
};

// Handle deleting of older alerts & old alerts cache
const deleteOldCache = () => {
	// Loop over each key inside the cache, and delete any that are older than 1 day
	const now = Date.now();
	const cache = utils.local.getLocal('new_dash_alerts', {});
	Object.keys(cache).forEach((key) => {
		const value = cache[key];
		if (now - value.updated > timeUtils.units.DAY_MS) {
			delete cache[key];
		}
	});
	utils.local.setLocal('new_dash_alerts', cache);
	// Delete the legacy cache (which was not msgpack encoded)
	utils.local.deleteLocal('new_dash');
};

const deleteOldCacheUID = (uid: string) => {
	const cache = utils.local.getLocal('new_dash_alerts', {});
	delete cache[uid];
	utils.local.setLocal('new_dash_alerts', cache);
};

export const formatUserAlertLink = (link?: string) => {
	if (!link) return '';
	if (utils.isLocal() && link.startsWith('https://lab.alpineiq.com')) link = link.replace('https://lab.alpineiq.com', '');

	// We update legacy recipe links to new format
	if (link.includes('/recipe')) {
		let updated = '';
		if (link.includes('builder')) {
			const [, retailerID, targetRecipeID] = link.match(/\/recipes\/(\d+)\/builder\/(\d+)/) || [];
			updated = `/templates/${retailerID || ':uid'}/builder?recipe=${targetRecipeID}&z=9001`;
		}
		if (link.includes('manage')) {
			const [, brandSponsorID, sponsoredRecipeID] = link.match(/\/recipes\/(\d+)\/manage\/recipe=(\d+)/) || [];
			updated = `/templates/${brandSponsorID || ':uid'}/builder?recipe=${sponsoredRecipeID}`;
		}
		// Any that we didn't convert wont work so we just clear them
		link = updated;
	}

	return `${link || ''}${link.includes('?') ? '&' : '?'}ignoreAlerts=true`.replace(':uid', utils.uid);
};

export const sampleUserAlerts: UserAlert[] = [
	{
		id: '-2',
		type: 'critical',
		prefix: { value: 'Critical Issue' },
		text: { value: 'API is offline' },
		hover: {
			value: `This is a CRITICAL error, please try to resolve as soon as possible. Ensure there's no spaces in the key. If not, please contact your integration provider to resolve.`,
		},
	},
	{
		id: '-2',
		type: 'error',
		prefix: { value: 'API Error' },
		text: { value: 'The API information entered is incorrect (key/username/password or wrong access granted to key).' },
		hover: { value: `This is an example` },
	},
	{
		id: '-2',
		type: 'info',
		prefix: { value: 'Settings Changed' },
		text: { value: 'Sub-user: xemail@gmail.com has updated the user settings for your account' },
		hover: {
			value: `This is a CRITICAL error, please try to resolve as soon as possible. Ensure there's no spaces in the key. If not, please contact your integration provider to resolve.`,
		},
	},
	{
		id: '-2',
		type: 'warning',
		prefix: { value: 'Deprecated Settings' },
		text: { value: 'We recommend you upgrade to our new and improved settings' },
		hover: {
			value: `This is a CRITICAL error, please try to resolve as soon as possible. Ensure there's no spaces in the key. If not, please contact your integration provider to resolve.`,
		},
	},
	{
		id: '-2',
		type: 'recipe',
		prefix: { value: 'Recipe Asset Activated!' },
		text: { value: 'Co-Marketing Template (1234) Email Send has been activated' },
		hover: {
			value: `This is a CRITICAL error, please try to resolve as soon as possible. Ensure there's no spaces in the key. If not, please contact your integration provider to resolve.`,
		},
	},
	{
		id: '-2',
		type: 'debug',
		prefix: { value: 'Wallet Failed' },
		text: { value: 'Failed to update user wallet info for user.' },
		suffix: { value: '<a>Click here</a> to download JSON', style: { fontSize: '.75rem' } },
		hover: {
			value: `This is a CRITICAL error, please try to resolve as soon as possible. Ensure there's no spaces in the key. If not, please contact your integration provider to resolve.`,
		},
	},
	{
		id: '-2',
		type: 'success',
		prefix: { value: 'No alerts exist at this time', color: '#000' },
	},
];

export const sampleFeatureHighlightsAlerts: UserAlert[] = [
	{
		id: '-2',
		type: 'critical',
		featureHighlight: true,
		prefix: { value: 'Critical Issue' },
		text: { value: 'API is offline' },
		button: { value: 'Resolve' },
		hover: {
			value: `This is a CRITICAL error, please try to resolve as soon as possible. Ensure there's no spaces in the key. If not, please contact your integration provider to resolve.`,
		},
		link: '/',
	},
	{
		id: '-2',
		type: 'error',
		featureHighlight: true,
		prefix: { value: 'API Error' },
		text: { value: 'The API information entered is incorrect (key/username/password or wrong access granted to key).' },
		button: { value: 'View documentation' },
		hover: { value: `This is an example` },
		link: '/',
	},
	{
		id: '-2',
		type: 'info',
		featureHighlight: true,
		prefix: { value: 'Settings Changed' },
		text: { value: 'Sub-user: xemail@gmail.com has updated the user settings for your account' },
		button: { value: 'Check settings' },
		hover: {
			value: `This is a CRITICAL error, please try to resolve as soon as possible. Ensure there's no spaces in the key. If not, please contact your integration provider to resolve.`,
		},
		link: '/',
	},
	{
		id: '-2',
		type: 'warning',
		featureHighlight: true,
		prefix: { value: 'Deprecated Settings' },
		text: { value: 'We recommend you upgrade to our new and improved settings' },
		button: { value: 'Upgrade' },
		hover: {
			value: `This is a CRITICAL error, please try to resolve as soon as possible. Ensure there's no spaces in the key. If not, please contact your integration provider to resolve.`,
		},
		link: '/',
	},
	{
		id: '-2',
		type: 'recipe',
		featureHighlight: true,
		prefix: { value: 'Recipe Asset Activated!' },
		text: { value: 'Co-Marketing Template (1234) Email Send has been activated' },
		button: { value: 'View Recipe' },
		hover: {
			value: `This is a CRITICAL error, please try to resolve as soon as possible. Ensure there's no spaces in the key. If not, please contact your integration provider to resolve.`,
		},
		link: '/',
	},
	{
		id: '-2',
		type: 'debug',
		featureHighlight: true,
		prefix: { value: 'Wallet Failed' },
		text: { value: 'Failed to update user wallet info for user.' },
		suffix: { value: '<a>Click here</a> to download JSON', style: { fontSize: '.75rem' } },
		button: { value: 'View JSON' },
		hover: {
			value: `This is a CRITICAL error, please try to resolve as soon as possible. Ensure there's no spaces in the key. If not, please contact your integration provider to resolve.`,
		},
		link: '/',
	},
	{
		id: '-2',
		type: 'success',
		featureHighlight: true,
		button: { value: 'View all' },
		prefix: { value: 'No alerts exist at this time', color: '#fff' },
		link: '/',
	},
];

export const Q1ALERT = {
	id: '28376',
	created: 1711440711,
	updated: 1711441581,
	creator: 'brynner.doyle@alpineiq.com',
	manuallyCreated: true,
	featureHighlight: true,
	youtubeVideo: 'https://www.youtube.com/embed/QjmqA_sR7Js?si=vV2lMX7AoM2vD0cD',
};

export const ECOM_ALERT: UserAlert = {
	manuallyCreated: true,
	creator: 'brynner.doyle@alpineiq.com',
	color: '#6F4CFB',
	flipImage: true,
	featureHighlight: true,
	slideshowPages: {
		0: {
			prefix: { value: 'AIQ Ecommerce launches 🚀', color: '#fff' },
			text: { value: 'Easily host highly SEO optimized menus that seamlessly integrate across marketing, loyalty, apps, and customer flows.' },
			bulletItems: [
				{ value: 'Single sign on checkout across desktop, mobile, and native apps' },
				{ value: 'Abandoned cart marketing automation' },
				{ value: 'Feed orders directly into your favorite point-of-sale systems and payment providers' },
				{ value: "Customize your look and feel or build custom with no limits API's" },
			],
		},
		1: {
			prefix: { value: 'Build loyalty the right way', color: '#fff' },
			text: {
				value:
					'Customers can easily sign up for your rewards program from your checkout page. Existing loyalty members can access their digital wallet at time of purchase to accrue or redeem rewards.',
			},
			bulletItems: [
				{ value: 'Scale loyalty sign ups faster than ever' },
				{ value: 'Let shoppers earn status badges with gamification' },
				{ value: 'Access rewards with phone or email based lookups' },
			],
		},
		2: {
			prefix: { value: 'Manage orders more efficiently', color: '#fff' },
			text: {
				value:
					"We help store managers and their teams handle orders with ease, no matter if they're in-store purchases, delivery, pick up, or direct-to-consumer purchases.",
			},
			bulletItems: [{ value: 'Customers can schedule pickup windows' }, { value: 'Manage every order type in the same place' }],
		},
	},
};

// @ts-ignore
window['userAlertsCache'] = {
	getRaw: () => utils.local.getLocal('new_dash_alerts', {}),
	getCachedUserAlerts,
	updateCachedAlerts,
	deleteOldCacheUID,
};
