import { isEqual } from 'lodash';

import { asyncWrap, fetchAPI } from '../../../api';
import TimezoneSelect from '../../../components/timezones';
import { timeUtils, userUtils, utils } from '../../../helpers';
import {
	Audience,
	Campaign,
	CampaignChannel,
	CampaignChannelsMap,
	CampaignChannelType,
	CampaignConversionActionMap,
	CampaignMap,
	ConversionAction,
	DateRange,
	EmailTemplate,
	EntryMap,
	SendWindow,
	SMSTemplate,
} from '@/legacy-types';
import { CampaignBuilderStagesProps, CampaignType, CampaignTypesMapping, ChannelInfo, ChannelInput, SaveCampaignOption } from './cmp.builder.types';
import { bannedMacrosByTemplate, CampaignBuilderMacros, getLinkMacros, Macro, warningMacros } from './macros';
import { isTelnyxCampaignApproved } from '../../../helpers/telynxSettings';

export function showSidebarInCampaigns() {
	return utils.local.getLocal('showSidebarInCampaigns') || false;
}

export function setShowSidebarInCampaigns(show: boolean) {
	if (show) utils.local.setLocal('showSidebarInCampaigns', true);
	else utils.local.deleteLocal('showSidebarInCampaigns');
}

export function cleanCopyCampaign(campaign: Campaign): Campaign {
	const copy = utils.clone(campaign) as Campaign;
	delete copy.lastMessage;
	if (copy.summary) copy.summary = undefined;
	copy.clonedFrom = `${copy.id || ''}`;
	copy.name += ' (Copy)';
	copy.isActive = false;
	copy.created = 0;
	copy.recipe = {};
	copy.recipes = {};
	copy.isLocked = false;
	copy.id = undefined;
	if (!utils.AYR.includes(utils.uid) && !utils.hideVoiceConvs.includes(utils.uid)) {
		copy.conversionActions = Array.from(new Set([...(copy.conversionActions || []), 'call_vm', 'call_answer']));
	}
	if (copy.enableQueue) {
		copy.scheduled = Math.floor(Date.now() / 1000) - timeUtils.units.MINUTE_S * 5;
	}
	return copy;
}

export function isChannelEmpty(channel?: CampaignChannel): boolean {
	const copy: CampaignChannel = utils.clone(channel || {});
	if (copy.priority) delete copy.priority;
	if (Object.keys(copy).length === 0) return true;
	return false;
}

export function getTriggers(campaign: Campaign): Campaign['triggers'] {
	const triggers: Campaign['triggers'] = utils.clone(campaign.triggers || {});
	delete triggers?.geoFence;
	delete triggers?.geoFenceConditions;
	return triggers;
}

const getAllChannels = () => Object.keys(CampaignChannelsMap) as CampaignChannelType[];

export function getCampaignChannels(input: ChannelInput): (ChannelInfo & CampaignChannel)[] {
	// IF input is a campaign, we assume that it is a waterfall campaign
	if (input.isWaterfall) {
		return getAllChannels()
			.map((channel) => {
				const channelCampaign = input.campaign[channel];
				return {
					channel,
					isSelected: !isChannelEmpty(channelCampaign),
					...channelCampaign,
				} as ChannelInfo;
			})
			.sort((a, b) => {
				// If both are undefined we sort by default
				if (a.priority === undefined && b.priority === undefined) {
					const defaultA = CampaignChannelsMap[a.channel].defaultPriority;
					const defaultB = CampaignChannelsMap[b.channel].defaultPriority;
					return defaultA - defaultB;
				}
				// undefined priorities are sorted to the end
				if (a.priority === undefined) return 1;
				if (b.priority === undefined) return -1;
				return (a?.priority || 0) - (b?.priority || 0);
			});
	}
	return getAllChannels()
		.map((channel) => {
			const channelCampaign = input.campaigns[channel]?.[channel];
			return {
				channel,
				isSelected: !isChannelEmpty(channelCampaign),
				...channelCampaign,
			} as ChannelInfo;
		})
		.sort((a, b) => {
			const defaultA = CampaignChannelsMap[a.channel].defaultPriority;
			const defaultB = CampaignChannelsMap[b.channel].defaultPriority;
			return defaultA - defaultB;
		});
}

type ChannelChanges = {
	[key in CampaignChannelType]: {
		channel: CampaignChannelType;
		start: number;
		end: number;
	};
};

export function validateCampaignChannelPriority(campaign: Campaign): { campaign: Campaign; changed: ChannelChanges } {
	// Get all channels
	const changed = getAllChannels()
		// Filter out channels that are not selected
		.filter((channel) => !isChannelEmpty(campaign[channel]) && campaign[channel]?.priority !== undefined && CampaignChannelsMap[channel].isVisible())
		// Map to channel and priority
		.map((channel) => ({ channel, priority: campaign[channel]?.priority as number }))
		// Sort by priority
		.sort((a, b) => a.priority - b.priority)
		// Update channels with invalid priority and return the changes
		.reduce((acc, chan, index) => {
			const { channel, priority } = chan;
			if (priority > index) {
				(campaign[channel] as CampaignChannel).priority = index;
				acc[channel] = { channel, start: priority, end: index };
			}
			return acc;
		}, {} as ChannelChanges);
	return { campaign, changed };
}

type ChannelPriorityUpdate = {
	campaign: Campaign;
	changed: boolean;
	changes?: ChannelChanges;
};

export function setCampaignChannelPriority(campaign: Campaign, channel: CampaignChannelType, priority: number): ChannelPriorityUpdate {
	let copy: Campaign = utils.clone(campaign);

	const campaignChannels = getCampaignChannels({ isWaterfall: true, campaign: copy });

	const maxPriorityAllowed = campaignChannels.filter((chan) => chan.isSelected).length - 1;
	const currentPriority: number = copy[channel]?.priority || 0;
	const newPriority = Math.max(0, Math.min(priority, maxPriorityAllowed));

	if (userUtils.debugMode()) {
		console.log('%c--------------------%csetCampaignChannelPriority%c--------------------', 'color: green', 'color: aqua', 'color: green');
		console.log('channel:', channel);
		console.log('priority:', priority);
		console.log('copy:', copy);
		console.log('campaignChannels:', campaignChannels);
		console.log('maxPriorityAllowed:', maxPriorityAllowed);
		console.log('currentPriority:', currentPriority);
		console.log('newPriority:', newPriority);
		console.log('%c--------------------%csetCampaignChannelPriority%c--------------------', 'color: green', 'color: aqua', 'color: green');
	}

	// If currentyPriority === newPriority, we don't need to do anything
	if (currentPriority === newPriority) return { campaign, changed: false };

	const hasCurrentPriority = currentPriority !== undefined;
	const shiftingLeft = hasCurrentPriority && currentPriority > newPriority;
	const shiftingRight = hasCurrentPriority && currentPriority < newPriority;
	const distance = Math.abs(newPriority - currentPriority);

	// If we are shifting over by just one, we can just swap the priorities
	if (distance === 1) {
		const channelToSwap = campaignChannels.find((chan) => chan.priority === newPriority)?.channel;
		if (channelToSwap) {
			copy[channelToSwap] = { ...copy[channelToSwap], priority: currentPriority };
			copy[channel] = { ...copy[channel], priority: newPriority };
		}
		return { campaign: copy, changed: true };
	}

	const channelsToUpdate = campaignChannels
		.filter((chan) => chan.isSelected && chan.priority !== currentPriority)
		.filter((chan) => {
			const isNewPriority = chan.priority === newPriority;
			const isAboveNewPriority = chan.priority > newPriority && shiftingLeft;
			const isBelowNewPriority = chan.priority < newPriority && shiftingRight;
			return isNewPriority || isAboveNewPriority || isBelowNewPriority;
		})
		.map((chan) => chan.channel);

	// Update the priority of the channels in between
	channelsToUpdate.forEach((chan) => {
		const channelPriority = copy[chan]?.priority;
		if (channelPriority === undefined) return;
		const isAboveNewPriority = channelPriority >= newPriority && shiftingLeft;
		const isBelowNewPriority = channelPriority <= newPriority && shiftingRight;
		if (isAboveNewPriority && channelPriority < currentPriority) {
			copy[chan] = { ...copy[chan], priority: channelPriority + 1 };
			console.log(`Chan: ${chan} | Priority: ${channelPriority} | New Priority: ${channelPriority + 1}. (Is Above)`);
		}
		if (isBelowNewPriority && channelPriority > currentPriority) {
			copy[chan] = { ...copy[chan], priority: channelPriority - 1 };
			console.log(`Chan: ${chan} | Priority: ${channelPriority} | New Priority: ${channelPriority - 1}. (Is Below)`);
		}
	});

	// Set the priority of the channel
	copy[channel] = { ...copy[channel], priority: newPriority };

	// Validate the campaign chanenls are in bounds, though should be unnecessary
	copy = validateCampaignChannelPriority(copy).campaign;

	return { campaign: copy, changed: true };
}

// This gets merged with existing campaign so ensure values added here are always included in the campaign struct
// ! (no omitempty)'s or they will be removed
export const DefaultCampaign = (tempID?: string, campaign: Campaign = {}): Campaign => {
	return {
		draft: true,
		blast: true,
		type: 'TEXT',
		recurDays: -1,
		conversionWindow: 14,
		timezone: TimezoneSelect.DefaultTZ,
		conversionActions: (Object.keys(CampaignConversionActionMap) as ConversionAction[]).filter((key) => CampaignConversionActionMap[key].defaultEnabled),
		...campaign,
		tempID,
	} as Campaign;
};

type ScheduleContent = {
	dateRange?: DateRange;
	sendWindow?: SendWindow;
	blast?: boolean;
	scheduled?: number;
	// sendAtTimePredicted?: boolean
};

export function haveSameSchedule(campaigns: Campaign[]): boolean {
	const schedule: ScheduleContent = {
		dateRange: campaigns[0].dateRange,
		blast: campaigns[0].blast,
		scheduled: campaigns[0].scheduled,
		sendWindow: campaigns[0].sendWindow,
		// sendAtTimePredicted: campaigns[0].sendAtTimePredicted,
	};

	for (let i = 1; i < campaigns.length; i++) {
		const campaign = campaigns[i];
		// if campaign has a different date range, return false
		if (schedule.dateRange) {
			if (!campaign.dateRange) return false;
			if (campaign.dateRange.start !== schedule.dateRange.start) return false;
			if (campaign.dateRange.end !== schedule.dateRange.end) return false;
		}
		// if campaign has a different blast, return false
		if (campaign.blast !== schedule.blast) return false;
		// if campaign has a different scheduled, return false
		if (campaign.scheduled !== schedule.scheduled) return false;
		// if campaign has a different sendWindow, return false
		if (schedule.sendWindow) {
			if (!campaign.sendWindow) return false;
			if (campaign.sendWindow.start !== schedule.sendWindow.start) return false;
			if (campaign.sendWindow.end !== schedule.sendWindow.end) return false;
		}
		// if campaign has a different sendAtTimePredicted, return false
		// if (campaign.sendAtTimePredicted !== schedule.sendAtTimePredicted) return false
	}

	return true;
}

// Return an object with only the schedule keys that are shared
export function getCampaignSchedule(campaign: Campaign): ScheduleContent {
	if (!campaign) return {};
	const schedule: ScheduleContent = {
		dateRange: campaign.dateRange,
		blast: campaign.blast,
		scheduled: campaign.scheduled,
		sendWindow: campaign.sendWindow,
		// sendAtTimePredicted: campaign.sendAtTimePredicted,
	};
	// Remove keys that are undefined as to not override the default values
	for (const key of Object.keys(schedule) as (keyof ScheduleContent)[]) {
		if (schedule[key] === undefined) delete schedule[key];
	}
	return utils.clone(schedule);
}

export function getCampaignType(campaign: Campaign): CampaignType {
	const channelTypes = (Object.keys(CampaignTypesMapping) as CampaignType[]).map((key) => {
		const typeInfo = CampaignTypesMapping[key];
		const hasType = typeInfo.check(campaign);
		return { key, hasType };
	});

	return channelTypes.find((type) => type.hasType)?.key || 'normal';
}

type InfoValidation = {
	[key in keyof Campaign]: string | ((campaign: Campaign, next: Campaign, channel: CampaignChannelType) => boolean);
};
const informationKeys: InfoValidation = {
	name: (prev, next, channel) => {
		// Remove the "- <Channel>" from the name
		const channelName = CampaignChannelsMap[channel].shortName;
		const channelNameIndex = (prev.name || '').indexOf(channelName);
		const prevName = (prev.name || '').slice(0, channelNameIndex).trim();
		const nextName = (next.name || '').slice(0, channelNameIndex).trim();
		return prevName === nextName;
	},
	storeIDHash: (prev, next) => isEqual(prev.storeIDHash, next.storeIDHash),
	triggers: (prev, next) => isEqual(getTriggers(prev), getTriggers(next)),
	audiences: (prev, next) => isEqual(prev.audiences, next.audiences),
	exclude: (prev, next) => isEqual(prev.exclude, next.exclude),
	storeIDs: (prev, next) => isEqual(prev.storeIDs, next.storeIDs),
	tags: 'tags',
	capPerDay: 'capPerDay',
	sendAtTimePredicted: 'sendAtTimePredicted',
} as const;

export function isCampaignInformationMatching(channelMap: CampaignMap): boolean {
	const channels = Object.keys(channelMap) as CampaignChannelType[];
	const channel = channels[0];
	const campaign = channelMap[channel] as Campaign;

	for (let i = 1; i < channels.length; i++) {
		const otherChannel = channels[i];
		const otherCampaign = channelMap[otherChannel] as Campaign;
		for (const key of Object.keys(informationKeys) as (keyof Campaign)[]) {
			const value = informationKeys[key];
			if (typeof value === 'string') {
				if (JSON.stringify(campaign[key] || '') !== JSON.stringify(otherCampaign[key] || '')) return false;
			} else if (typeof value === 'function') {
				if (!value(campaign, otherCampaign, channel)) return false;
			}
		}
	}
	return true;
}
// Return a campaign object with only the information keys
export function getCampaignInformation(campaign: Campaign): Campaign {
	if (!campaign) return {};
	const campaignChannel = getCampaignChannelType(campaign);
	const newCampaign: Campaign = {};
	for (const key of Object.keys(informationKeys) as (keyof Campaign)[]) {
		let value = campaign[key];
		if (key === 'name' && campaignChannel) {
			// Remove the "- <Channel>" from the name
			const channelName = ` - ${CampaignChannelsMap[campaignChannel].shortName}`;
			const channelNameIndex = ((value || '') as string).indexOf(channelName);
			value = ((value || '') as string).slice(0, channelNameIndex).trim();
		}
		// @ts-ignore - TS doesn't like the type of informationKeys
		newCampaign[key] = value;
	}
	// Remove keys that are undefined as to not override the default values
	for (const key of Object.keys(newCampaign) as (keyof Campaign)[]) {
		if (newCampaign[key] === undefined) delete newCampaign[key];
	}
	return newCampaign;
}

export function addChannelNameToCampaigns(campaigns: CampaignMap, options?: SaveCampaignOption): CampaignMap {
	// Loop over the channels and add the channel name to the campaign. But only if all names are the same
	const channels = Object.keys(campaigns) as CampaignChannelType[];
	const firstChannel = channels[0] as CampaignChannelType;
	const firstCampaignName = campaigns[firstChannel]?.name || ('' as string);

	const newCampaigns: CampaignMap = utils.clone(campaigns);

	if (!!options) {
		channels.forEach((channel) => {
			const campaign = newCampaigns[channel];
			if (campaign) {
				if (options === 'publish') {
					campaign.isActive = true;
				}
				campaign.draft = false;
			}
		});
		return newCampaigns;
	}

	// Check if all of the channels have the same name
	const allSameName = channels.every((channel) => {
		const campaign = campaigns[channel];
		return campaign?.name === firstCampaignName;
	});

	// If they are not all the same, return
	if (!allSameName || Object.keys(campaigns || {}).length === 1) return newCampaigns;

	// If they are all the same, add the channel name to the campaign
	channels.forEach((channel) => {
		const campaign = newCampaigns[channel];
		if (campaign) {
			campaign.name = `${firstCampaignName} - ${CampaignChannelsMap[channel].shortName}`;
		}
	});
	return newCampaigns;
}

export function deleteAllChannels(campaign: Campaign): Campaign {
	const copy = utils.clone(campaign);
	getAllChannels().forEach((channel) => delete copy[channel]);
	return copy;
}

export function campaignHasRecipientsConfigured(campaign: Campaign): string[] {
	const errors = [] as string[];
	if (!campaign.name) errors.push('Campaign must have a name');
	if (!campaign.audiences || campaign.audiences.length === 0) {
		errors.push('Campaign must have at least one audience');
	}
	return errors;
}

export function validateCampaigns(args: CampaignBuilderStagesProps, requireContent = false): string[] {
	const errors: string[] = [];
	// Must select a channel before anything
	if (args.selectedCount === 0) errors.push('You must select at least one channel to create a campaign.');

	const isMatching = args.config.sameInformation && args.config.sameSchedule;
	// If we are in a waterfall campaign or the information is matching, we validate the campaign
	if (args.isWaterfall || isMatching) {
		const campaign = args.getCampaign();
		// Validate all of the channels
		if (requireContent) {
			args.channels
				.filter((channel) => channel.isSelected)
				.forEach((channel) => {
					// Check if channel has content
					const channelInfo = CampaignChannelsMap[channel.channel];
					const channelData = campaign[channel.channel] as CampaignChannel;
					if (channelInfo.hasContent && !channelInfo.hasContent(channelData || {}, campaign)) {
						errors.push(`${channelInfo.shortName} must have content before creating a campaign.`);
					}
				});
		}

		errors.push(...validateSingleCampaign(campaign));
	} else {
		// Otherwise we validate each of the campaigns
		for (const [channel, campaign] of Object.entries(args.campaigns) as [CampaignChannelType, Campaign][]) {
			if (requireContent) {
				const channelInfo = CampaignChannelsMap[channel];
				const channelData = campaign[channel] as CampaignChannel;
				// Check if channel has content
				if (channelInfo.hasContent && !channelInfo.hasContent(channelData || {}, campaign)) {
					errors.push(`${channelInfo.shortName} must have content before creating a campaign.`);
				}
			}

			const channelErrors = validateSingleCampaign(campaign);
			if (channelErrors.length > 0) {
				errors.push(...channelErrors.map((error) => `${CampaignChannelsMap[channel].shortName}: ${error}`));
			}
		}
	}
	return errors;
}
const dynamicRegex = new RegExp(/\{\{dynamic-[A-Za-z0-9]+-[A-Za-z0-9]+-[A-Za-z0-9]+\}\}/gi);
const isDynamic = (html: string) => dynamicRegex.test(html || '') || (html || '')?.includes(`id="dynamic`);

export function validateSingleCampaign(campaign: Campaign): string[] {
	const errors: string[] = [];

	if (!campaign.name) errors.push('Campaign must have a name');
	if (!campaign.audiences || campaign.audiences.length === 0) {
		errors.push('Campaign must have at least one audience');
	}

	// If campaign is optinCampin don't allow HTML landing pages
	if (campaign.optinCampaign && campaign.templates?.landingType === 'HTML') {
		errors.push('You cannot use HTML landing pages with opt-in campaigns!');
	}

	const hasQueue = campaign.enableQueue;
	const hasLifetimeCap = (campaign.lifetimeCap ?? 0) > 0;
	// Blast date cannot be in past (if set)
	// If they have lifetime cap, or queue enabled, we ignore the send date being in the past
	if (!hasQueue && !hasLifetimeCap && campaign.scheduled && campaign.scheduled < Date.now() / 1000) {
		errors.push('Send date cannot be in the past');
	}

	// If campaign has hashStoreId's then it is a sponsored campaign, and there cannot be store ID's and audiences
	if (campaign.storeIDHash && Object.keys(campaign.storeIDHash).length) {
		// If audiences is not just ['all] return err
		if ((campaign.audiences || []).length !== 1 && (campaign.audiences || [])[0] !== 'all') {
			errors.push('You must target "All subscribed users" when creating a campaign targeting connected stores.');
		}
		// If they have selected stores return err
		if (campaign.storeIDs?.length) {
			errors.push('You cannot select stores when creating a campaign targeting connected stores.');
		}
		// same for excluded audiences
		if (campaign.exclude?.length) {
			errors.push('You cannot exclude audiences when creating a campaign targeting connected stores.');
		}
	}

	// Retailer specific checks
	if (!utils.isBrand()) {
		if (campaign?.emailTemplate && !campaign?.emailTemplate.from) {
			errors.push('You must input a "From email" inside your email template if you wish to send emails!');
		}
	}

	// Check dynamic content
	[
		{
			type: 'Text Channel',
			html: campaign.templates?.landingHTML,
			json: campaign.templates?.landingDesign,
			dynamicContent: campaign.templates?.dynamicContent,
		},
		{
			type: 'Email Channel',
			html: campaign.emailTemplate?.html,
			json: campaign.emailTemplate?.json,
			dynamicContent: campaign.emailTemplate?.dynamicContent,
		},
	].forEach((info) => {
		if (info.html) {
			const htmlHasDynamicMacros = isDynamic(info.html);
			const sampleDataEnabled = JSON.stringify(info.json || {}).includes(`"showSampleData":true,`);
			const hasConfiguredContent = info?.dynamicContent || Object.keys(info?.dynamicContent || {}).length > 0;

			// If they have dynamic content, but no dynamic macros, throw an error
			if (hasConfiguredContent && !htmlHasDynamicMacros) {
				errors.push(`Add dynamic content before saving your ${info.type} with a "Product Feed" filter!`);
			}

			// If they have dynamic macros, but no dynamic content, throw an error
			if (htmlHasDynamicMacros && !hasConfiguredContent) {
				errors.push(`You must configure your dynamic content before saving your ${info.type} with dynamic macros!`);
			}

			if (sampleDataEnabled) {
				errors.push(`You must disable sample dynamic content data before saving! (You may need to toggle it on/off again)`);
			}
		}
	});

	checkInvalidMacroEnding(campaign.templates?.msgContainer || '');

	//
	// Bypassing this check for now
	//
	// If they are on 10dlc they are not allowed to have mmsMediaUrls
	// const templates = campaign.templates
	// if (utils.hasRestrictedIndustry10DLC() && templates) {
	// 	const mmsURL = templates?.mmsMediaURL
	// 	const hasMMSMedia = templates?.mediaURL && (templates.isMMS || templates.mmsOnly)
	// 	if (mmsURL || hasMMSMedia) {
	// 		errors.push('You are not allowed to use MMS media URLs in your campaign.')
	// 	}
	// }
	return errors;
}

export function lockMMSURL(campaign: Campaign): Campaign {
	if (campaign && campaign.templates) {
		if (utils.user.optinImage !== '') {
			campaign.templates.mmsMediaURL = utils.user.optinImage;
		} else {
			campaign.templates.mmsMediaURL = `https://lab.alpineiq.com/avatar/${uid}/-1?cacheBuster=${Date.now()}&mms=true`;
		}
		campaign.templates.mediaContentType = 'image';
	}
	return campaign;
}

export function removeUnusedProductSlugs(campaign: Campaign): Campaign {
	if (!!campaign.templates?.slugs?.length) {
		const textHtml = campaign.templates.landingHTML || '';
		// Check that the textHTML contains the slugs, if not remove them
		campaign.templates.slugs = campaign.templates.slugs.filter((slug) => textHtml.includes(slug));
	}

	if (!!campaign.emailTemplate?.slugs?.length) {
		const emailHtml = campaign.emailTemplate.html || '';
		// Check that the emailHTML contains the slugs, if not remove them
		campaign.emailTemplate.slugs = campaign.emailTemplate.slugs.filter((slug) => emailHtml.includes(slug));
	}
	return campaign;
}

export function checkInvalidMacroEnding(html: string): boolean {
	if (!html) return false;

	const linkMacros = getLinkMacros();
	const endMacroMatch = (html.match(/{{[^}]+}}[^? ][^ ]{0,5}/g) || [])
		// Filter out macros which don't need to be checked
		.filter((macroString) => {
			// Remove everything before & after the {{macro}}
			const realMacro = macroString.match(/{{[^}]+}}/g)?.[0] || '';
			// Check if the macro is a link macro
			if (!linkMacros.includes(realMacro)) return false;
			return true;
		});

	if (endMacroMatch.length) {
		utils.sendNotification({
			type: 'warning',
			message: 'Warning!',
			description: `Please do not follow macros with a character direcly after the closing "${endMacroMatch}", as it may cause issues with your template when sending your campaign.`,
		});
		return true;
	}
	return false;
}

export function hasCampaignChannel(input: ChannelInput, channel: CampaignChannelType): boolean {
	if (input.isWaterfall) {
		return !isChannelEmpty(input.campaign[channel]);
	}
	return !isChannelEmpty(input.campaigns[channel]?.[channel]);
}

export async function sendTestEmail(recipient: string, templateData: EmailTemplate): Promise<boolean> {
	const lastSend = utils.local.getLocal('lastTestEmailSend');
	const now = Date.now();
	const COOLDOWN = 30 * 1000;
	if (lastSend && now - lastSend < COOLDOWN) {
		const timeRemaining = Math.round((COOLDOWN - (now - lastSend)) / 1000);
		utils.showErr(`Please wait ${timeRemaining} seconds before sending another test email`);
		return false;
	}

	const email = recipient?.trim();
	if (!email) {
		utils.showErr('Missing email to send test to');
		return false;
	}

	if (!templateData.subject) {
		utils.showErr('Missing subject');
		return false;
	}

	if (!templateData.from) {
		utils.showErr('Missing from');
		return false;
	}

	if ((templateData.html?.length || 0) >= 300_000 && utils.uid !== '1033') {
		utils.showErr('Email body is too large');
		return false;
	}

	await asyncWrap(async () => {
		await utils.auth.post(`sendTestEmail/${utils.uid}/${email}`, { emailTemplate: templateData });
		utils.showSuccess(`Test email sent to ${email}`);
		utils.local.setLocal('lastTestEmailSend', now);
	});
	return true;
}

export async function sendTestText(recipient: string, templates: SMSTemplate): Promise<boolean> {
	const lastSend = utils.local.getLocal('lastTestTextSend');
	const now = Date.now();
	const COOLDOWN = 30 * 1000;
	if (lastSend && now - lastSend < COOLDOWN) {
		const timeRemaining = Math.round((COOLDOWN - (now - lastSend)) / 1000);
		utils.showErr(`Please wait ${timeRemaining} seconds before sending another test phone`);
		return false;
	}

	const phone = recipient?.trim();
	if (!phone) {
		utils.showErr('Missing phone to send test to');
		return false;
	}

	if (!utils.phoneRex.test(phone)) {
		utils.showErr('Invalid phone number');
		return false;
	}

	await asyncWrap(async () => {
		await fetchAPI(`/campaign/full/preview/:uid?sendToPhone=${phone}`, {
			payload: { id: 'DNE', templates },
			method: 'POST',
		});
		utils.showSuccess(`Test phone sent to ${phone}`);
		utils.local.setLocal('lastTestTextSend', now);
	});
	return true;
}

export type DisabledMap = { [key in CampaignChannelType]?: string[] };
export type DisabledCMP = {
	disabled: boolean;
	reasons?: string[];
	disabledMap?: DisabledMap;
};
export function isCampaignDisabled(input: ChannelInput): DisabledCMP {
	if (input.isWaterfall) {
		// If the campaign is locked, it is disabled
		return isDisabled(input.campaign);
	}
	const disabledCmp = { disabled: false, reasons: [], disabledMap: undefined } as DisabledCMP;
	for (const [channel, campaign] of Object.entries(input.campaigns) as [CampaignChannelType, Campaign][]) {
		const result = isDisabled(campaign);
		disabledCmp.disabledMap = { ...disabledCmp.disabledMap, [channel]: result.reasons || [] };
		if (result.disabled) {
			// aggregate disabled reasons
			disabledCmp.disabled = disabledCmp.disabled || result.disabled;
			disabledCmp.reasons = [...(disabledCmp.reasons || []), ...(result.reasons || [])];
		}
	}
	return { ...disabledCmp, reasons: Array.from(new Set(disabledCmp.reasons || [])) };
}

const isDisabled = (campaign: Campaign): DisabledCMP => {
	const reasons = [] as string[];
	// if (utils.isLocal() && campaign.id !== "1331") return { disabled: true, reasons: ['Local testing'] }
	if (campaign.userID !== '3652' && campaign.userID !== '1163' && hasCamaignSent(campaign)) {
		reasons.push('Cannot edit a campaign after it has already been sent');
	}
	// if (isPastSendDate(campaign)) reasons.push('Campaign has passed the send date')
	// if (isCampaignActive(campaign)) reasons.push('Cannot edit a campaign that is active')
	if (isCampaignLocked(campaign)) reasons.push(campaign.lockedReason ? `Locked: ${campaign.lockedReason}` : `Campaign is locked`);
	return {
		disabled: reasons.length > 0,
		reasons,
	};
};

export function isCampaignLocked(campaign: Campaign): boolean {
	return !!campaign.isLocked;
}

export function hasCamaignSent(campaign: Campaign): boolean {
	return !!campaign.lastMessage && !!campaign.blast && !(utils.isLocal() || utils.isStaging());
}

export function isCampaignActive(campaign: Campaign): boolean {
	return !!campaign.isActive;
}

export function isPastSendDate(campaign: Campaign, bufferTime?: number): boolean {
	if (campaign.blast) {
		if (!campaign.scheduled) return false;
		const sendTime = campaign.scheduled || 0;
		const now = Date.now() / 1000;
		const buffer = bufferTime || 0;
		return sendTime + buffer < now;
		// return !!campaign.scheduled && campaign.scheduled < Date.now() / 1000
	}
	if (campaign.dateRange?.active && campaign.dateRange?.end) {
		return campaign.dateRange.end < Date.now() / 1000;
	}
	return false;
}

// Get CampaignChannelType from Campaign.. only works if there is one channel
export function getCampaignChannelType(campaign: Campaign): CampaignChannelType | undefined {
	// Loop over channels and find first one that is not empty
	for (const channel of getAllChannels()) {
		if (!isChannelEmpty(campaign[channel])) return channel;
	}
	return undefined;
}

// Get campaign without any channel data
export function getCampaignWithoutChannel(campaign: Campaign): Campaign {
	if (!campaign) return {};
	const copy = utils.clone(campaign || {});
	getAllChannels().forEach((channel) => delete copy[channel]);
	return copy;
}

// Remove all info not required for forecast
export function cleanForecastableCampaign(campaign: Campaign, noChannel?: boolean): Campaign {
	const copy: Campaign = utils.clone(campaign || {});
	// return copy
	const cleaned: Campaign = {
		id: copy.id || '-1',
		storeIDHash: copy.storeIDHash,
		storeIDs: copy.storeIDs,
		audiences: copy.audiences,
		exclude: copy.exclude,
		optinCampaign: copy.optinCampaign,
		emailOptinCampaign: copy.emailOptinCampaign,
		//     scheduled: copy.scheduled,
		//     blast: copy.blast,
		//     dateRange: copy.dateRange,
		//     sendWindow: copy.sendWindow,
		//     sendAtTimePredicted: copy.sendAtTimePredicted,
		//     triggers: copy.triggers,
	};

	// Remove all channels that are empty
	if (!noChannel) {
		Object.keys(CampaignChannelsMap).forEach((channel) => {
			const channelData = copy[channel as CampaignChannelType];
			if (isChannelEmpty(channelData)) return;
			cleaned[channel as CampaignChannelType] = {
				inUse: true,
				...(channel === 'templates' && {
					msgContainer: copy.templates?.msgContainer,
					body: copy.templates?.body,
					isMMS: copy.templates?.isMMS,
					mmsOnly: copy.templates?.mmsOnly,
				}),
				...(channel === 'voiceTemplate' && {
					ttv: copy.voiceTemplate?.ttv,
					voiceFile: copy.voiceTemplate?.voiceFile,
				}),
			} as CampaignChannel;
		});
	}

	// Clean off any undefined values, or empty arrays
	return utils.trimObject(cleaned, true, false, true, true);
}

export type ContentValidationResults = {
	blankAudiences: string[];
	byTemplate: TemplateValidationResults;
};

export type TemplateValidationResults = { [key in CampaignChannelType]: TemplateValidationResult };
export type TemplateValidationResult = {
	brokenMacros?: string[];
	discountMacros?: string[];
	invaildMacros?: string[];
	warningMacros?: string[];
};

// Keys to check which can contain macros
const contentKeys = ['title', 'body', 'custom', 'html', 'msgContainer', 'landingHTML'];

export function validateCampaignContent(
	campaigns: Campaign[],
	channels: CampaignChannel & { channel: CampaignChannelType }[],
	audiences: EntryMap<Audience>,
	checkMacros = false,
): ContentValidationResults {
	const results: ContentValidationResults = {
		blankAudiences: [],
		byTemplate: {
			adTemplate: {},
			emailTemplate: {},
			voiceTemplate: {},
			templates: {},
			browserTemplate: {},
			pushTemplate: {},
			snailMailTemplate: {},
		},
	};

	// Create a set of all audiences
	const allAudiences = new Set(campaigns.flatMap((campaign) => campaign.audiences || []));

	// Check if any audiences are
	for (const audienceID of Array.from(allAudiences)) {
		if (audienceID === 'all') {
			results.blankAudiences = [];
			break;
		}
		const result = audiences[audienceID];
		if (result && !result.traits?.length && (result.sources?.includes(-1) || !result.sources?.length)) {
			results.blankAudiences.push(result.id || '');
		}
	}

	if (checkMacros) {
		// Loop over each channel, and check if the content
		channels.forEach((channel) => {
			const channelKey = channel.channel;
			let checkText: string = contentKeys.reduce((text, key) => (key in channel ? text + (channel as any)[key] : text), '').toLowerCase();

			console.log(`Checking ${channelKey} for macros`);

			if (!checkText) return;

			const allValidMacros = findValidMacros(checkText);
			// const allValidMacros = checkText.match(/(?:{{(.+?)}})/g);

			// Remove all valid macros from the checkText
			if (allValidMacros) {
				for (const macro of allValidMacros) {
					if (channelKey in bannedMacrosByTemplate) {
						const bannedMacros = bannedMacrosByTemplate[channelKey as CampaignChannelType];
						if (bannedMacros?.includes(macro)) {
							const invalidMacroList = results.byTemplate[channelKey as CampaignChannelType].invaildMacros || [];
							results.byTemplate[channelKey as CampaignChannelType].invaildMacros = [...invalidMacroList, macro];
						}
					}
					if (warningMacros.includes(macro)) {
						const warninMacroList = results.byTemplate[channelKey as CampaignChannelType].warningMacros || [];
						results.byTemplate[channelKey as CampaignChannelType].warningMacros = [...warninMacroList, macro];
					}

					if (!checkText) break;

					checkText = checkText.replaceAll(macro, '');
				}
			}

			// all banned macros from all te mplates
			const allBannedMacros = (Object.values(bannedMacrosByTemplate) as string[][]).flat();

			// (Object.entries(CampaignBuilderMacros)).forEach(([key, value]: [string, Macro]) => {
			for (const [key, value] of Object.entries(CampaignBuilderMacros)) {
				let matchValue: string | RegExp = utils.strify(value.macro);
				if ('regex' in value) {
					const regex = value.regex as RegExp;
					// IF macro is "discounts", use regex to add all discount macros to discountMacros
					if (key === 'discounts') {
						const discountMacros = checkText.match(regex);
						if (discountMacros) {
							results.byTemplate[channelKey as CampaignChannelType].discountMacros = discountMacros;
						}
					}
					matchValue = regex;
				}

				if (!checkText) break;

				if (warningMacros.includes(value.macro.toLowerCase()) || allBannedMacros.includes(value.macro.toLowerCase())) {
					checkText?.replaceAll(matchValue, '');
				}
			}

			checkText = checkText
				.replace(/<style.*?>[\s\S]*?<\/style>|style="[a-zA-Z0-9:;\.\s\(\)\-\,]*"|<[^>]*>|\s+/gi, '') // Remove all whitespace and style tags and html tags
				.replace(/\s+/g, '') // Remove all whitespace
				.replace(/\s+|<style[\s\S]*?<\/style>|style="[a-zA-Z0-9:;\.\s\(\)\-\,]*"|<style.*?>[\s\S]*?<\/style>|style.*?>[\s\S]*?<\/style>/gi, '') // Remove all style tags
				// Remove all basic html
				// .replace(/<[^>]*>/g, '')
				.trim();

			if (!checkText) return;

			if (checkText.length && (checkText.includes('{') || checkText.includes('}'))) {
				const brokenMacrosMap = new Map();
				[...(checkText.match(/\{\{[^}\n]*\}|\{[^}\n]*\}\}?|[^{\n]*?\}\}(?!\})/g) || []), ...(checkText.match(/\{\{[^}\n]*?(?=\{|$)|[^{\n]*?\}\}/g) || [])].forEach(
					(macro) => {
						const cleaned = macro.replaceAll('{', '').replaceAll('}', '');
						// Strip the macro length to a max of 50 characters
						brokenMacrosMap.set(cleaned, macro.length > 32 ? `${macro.slice(0, 32)}...` : macro);
					},
				);

				if (brokenMacrosMap.size > 0) {
					results.byTemplate[channelKey as CampaignChannelType].brokenMacros = Array.from(brokenMacrosMap.values());
				}
			}
		});
	}

	return results;
}

function findValidMacros(checkText: string): string[] {
	const regexPatterns = [
		/\{\{[^{}]+\}\}/g, // Standard Macro Matching
		/\{\{[a-zA-Z0-9_]+\}\}/g, // Macro Matching with Specific Characters
		/\{\{[^{}:;]+?\}\}/g, // Macro Matching with Negated Set
	];

	// Find all matches for each regex pattern
	const validMacros = new Set<string>();
	regexPatterns.flatMap((regex) => checkText.match(regex) || []).forEach((macro) => validMacros.add(macro.toLowerCase()));

	return Array.from(validMacros);
}

export function canTestSendTextMessages() {
	if (utils.isDispojoy() || utils.isMessageDigitial()) {
		return true;
	}
	if (utils.isPolitical()) {
		return true;
	}
	if (utils.isDevUID()) {
		return true;
	}
	return utils.hasApprovedTelnyxCampaign();
}
