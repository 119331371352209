import React from "react";
import { colors, generateRandomString, generateRandomWord, timeUtils, utils } from "../../../helpers";
import { SearchDataType } from "../../../hooks/useSearchDebounce";
import { BrandCrewGoalData, BrandCrewLocationData, BrandCrewMemberData, BrandSnapshot, RetailerCrewGoalData, RetailerCrewLocationData, RetailerCrewMemberData, RetailerSnapshot } from "../pages/leaderboard/components/leaderboard.helpers";
import { BrandCrewGoal, RetailCrewGoal } from "./CrewGoalTypes";

export const CrewLeaderboardTypes = {
    members: 'Crew',
    locations: 'Location',
    goals: 'Goal'
} as const;
export type CrewLeaderboardType = keyof typeof CrewLeaderboardTypes;

export const Crew_TopSellingTypes = {
    brands: 'Brands',
    products: 'Products',
    categories: 'Categories'
} as const;

export type TopSellingType = keyof typeof Crew_TopSellingTypes;

export const Leaderboard_ViewingVersions = {
    retailer: {
        title: () => "My Company",
        description: () => 'Leaderboards for your company (crew, locations, goals)',
        show: () => utils.isVertical()
    },
    brand: {
        title: () => "Sponsored",
        description: () => 'Leaderboards from sponsored goals (crew, locations, goals)',
        show: () => utils.isVertical()
    }
} as const

export type LBVVConfig = typeof Leaderboard_ViewingVersions[keyof typeof Leaderboard_ViewingVersions];
export type LeaderboardViewingVersion = keyof typeof Leaderboard_ViewingVersions;

export const DateRangeDayPeriods = {
    all: {
        label: 'All Time',
        getTime: () => {
            return { start: undefined, end: undefined }
        }
    },
    '90days': {
        label: '90 Days',
        getTime: () => {
            const today = timeUtils.getTodayUnix();
            return {
                start: today.start - timeUtils.units.DAY_S * 90,
                end: today.end
            }
        }
    },
    '30days': {
        label: '30 Days',
        getTime: () => {
            const today = timeUtils.getTodayUnix();
            return {
                start: today.start - timeUtils.units.DAY_S * 30,
                end: today.end
            }
        }
    },
    '15days': {
        label: '15 Days',
        getTime: () => {
            const today = timeUtils.getTodayUnix();
            return {
                start: today.start - timeUtils.units.DAY_S * 15,
                end: today.end
            }
        }
    },
    '7days': {
        label: '7 Days',
        getTime: () => {
            const today = timeUtils.getTodayUnix();
            return {
                start: today.start - timeUtils.units.DAY_S * 7,
                end: today.end
            }
        }
    },
} as const;

export const goalMessages = {
    notStarted: {
        title: 'Pending',
        tooltip: 'This goal has not started yet',
        icon: {
            name: 'hourglass-start',
        },
        style: {
            color: colors.primary.blue,
            borderColor: colors.primary.blue,
        }
    },
    isActive: {
        title: 'Active',
        tooltip: 'This goal is active',
        icon: {
            name: 'check',
        },
        style: {
            color: colors.primary.primary,
            borderColor: colors.primary.primary,
        }
    },
    nearlyEnded: {
        title: 'Ending Soon',
        tooltip: 'This goal is ending soon',
        icon: {
            name: 'hourglass-half',
        },
        style: {
            color: colors.primary.pink,
            borderColor: colors.primary.pink,
        }
    },
    ended: {
        title: 'Ended',
        tooltip: 'This goal has ended',
        icon: {
            name: 'hourglass-end',
        },
        style: {
            color: colors.primary.error500,
            borderColor: colors.primary.error500,
        }
    },
    final: {
        title: 'Final',
        tooltip: 'This goal is final',
        icon: {
            name: 'flag-checkered',
        },
        style: {
            color: colors.primary.error500,
            borderColor: colors.primary.error500,
        }
    }
}


export type DateRangeDayPeriod = keyof typeof DateRangeDayPeriods;

export interface Entity {
    id: string | number;
    name: string;
}

export interface CrewLeaderboardFilters {
    StartDate?: number;
    EndDate?: number;
    Locations?: number[];
    Brands?: string[];
    ProductNames?: string[];
    BrandAttribuitionUID?: string;
}
export interface LeaderboardTarget {
    id: string | undefined;
    type: CrewLeaderboardType
};

export type CrewGoals = {
    retailer?: {
        loaded?: boolean;
        data?: { [key: string]: RetailCrewGoal }
    }
    brand?: {
        loaded?: boolean;
        data?: { [key: string]: BrandCrewGoal }
    }
}

export type CrewLeaderboardProps = {
    uid: string
    searchData: SearchDataType

    viewType: CrewLeaderboardType
    setViewType: React.Dispatch<React.SetStateAction<CrewLeaderboardType>>

    viewingVersion: LeaderboardViewingVersion
    setViewingVersion: React.Dispatch<React.SetStateAction<LeaderboardViewingVersion>>

    filters: CrewLeaderboardFilters
    setFilters: React.Dispatch<React.SetStateAction<CrewLeaderboardFilters>>

    drawerTarget: LeaderboardTarget | undefined
    setDrawerTarget: React.Dispatch<React.SetStateAction<LeaderboardTarget | undefined>>

    retailSnapshot: RetailerSnapshot
    brandSnapshot: BrandSnapshot

    retailCrewMemberData: RetailerCrewMemberData
    brandCrewMemberData: BrandCrewMemberData

    retailCrewLocationData: RetailerCrewLocationData
    brandCrewLocationData: BrandCrewLocationData

    retailCrewGoalData: RetailerCrewGoalData
    brandCrewGoalData: BrandCrewGoalData

    crewGoals: CrewGoals
    setCrewGoals: React.Dispatch<React.SetStateAction<CrewGoals>>

}
export interface LeaderboardDrawerProps extends CrewLeaderboardProps {
    target: MemberLeaderboardEntry | MemberLeaderboardDashFormat | undefined
}

// NOTE LEADERBOARD DATA TYPES

export type SalesMetrics = {
    units: number;
    revenue: number;
    transactions: number;
    // Average Cart Value, defined as Revenue / Transactions
    acv: number;
    // Count of distinct products
    totalProducts: number;
}

export type LeaderboardDataset<T> = {
    loaded: boolean;
    data: T;
    priorFilters?: CrewLeaderboardFilters;
}

type SalesMetricContext = {
    key: number // Added by dash for ordering
    id: string
    name: string
}

export type FormattedSalesMetric = SalesMetricContext & SalesMetrics & {
    color?: string;
}

// NOTE BY MEMBERS ---> RETAILER

export type MemberLeaderboardEntry = SalesMetricContext & SalesMetrics & {
    topStore?: SalesMetricContext
}
export type MemberGoalBreakdown = MemberLeaderboardEntry & {
    goalsRollup: SalesMetrics
    totalRevenue: number
    goalRevenuePercentage: number
    byGoal: { [goalID: string]: GoalLeaderboardEntry }
}
export type ByMembersMap = { [memberID: string]: MemberLeaderboardEntry }
export type ByMembers = MemberLeaderboardEntry[]
export type ByMembersByDay = { [memberID: string]: { [date: string]: MemberLeaderboardEntry } }
export type ByMembersByGoal = { [memberID: string]: MemberGoalBreakdown }
export type MemberByBrandAndProduct = { [brandID: string]: { [productID: string]: MemberLeaderboardEntry } }
export type MemberByCategoryAndProduct = { [categoryID: string]: { [productID: string]: MemberLeaderboardEntry } }

// NOTE BY LOCATIONS ---> RETAILER

export type LocationLeaderboardEntry = SalesMetricContext & SalesMetrics
export type LocationGoalBreakdown = SalesMetricContext & {
    goalsRollup: SalesMetrics
    totalRevenue: number
    goalRevenuePercentage: number
    byGoal: { [goalID: string]: SalesMetrics }
}
export type ByLocationsMap = { [locationID: string]: LocationLeaderboardEntry }
export type ByLocation = LocationLeaderboardEntry[]
export type ByLocationByDay = { [locationID: string]: { [date: string]: LocationLeaderboardEntry } }
export type ByLocationByGoal = { [locationID: string]: LocationGoalBreakdown }
export type LocationByBrandAndProduct = { [brandID: string]: { [productID: string]: LocationLeaderboardEntry } }
export type LocationByCategoryAndProduct = { [categoryID: string]: { [productID: string]: LocationLeaderboardEntry } }

// NOTE BY GOALS ---> RETAILER

export type GoalLeaderboardEntry = SalesMetricContext & SalesMetrics
export type GoalLeaderboardEntryWithPercentage = GoalLeaderboardEntry & {
    goalRevenuePercentage: number
}
export type GoalLeaderboardWithRollup = {
    goalsRollup: SalesMetrics
    totalRevenue: number
    byGoal: GoalLeaderboardEntryWithPercentage[]
}
export type ByGoalMap = { [goalID: string]: GoalLeaderboardEntry }
export type ByGoal = GoalLeaderboardWithRollup
export type ByGoalByDay = { [goalID: string]: { [date: string]: GoalLeaderboardEntry } }
export type GoalByBrandAndProduct = { [brandID: string]: { [productID: string]: GoalLeaderboardEntry } }
export type GoalByCategoryAndProduct = { [categoryID: string]: { [productID: string]: GoalLeaderboardEntry } }

// NOTE BY MEMBERS ---> BRAND

export type MemberLeaderboardDashFormat = MemberLeaderboardEntry & {
    byUID: { [uid: string]: MemberLeaderboardEntry }
}
export type ByMembersBrandMap = { [memberID: string]: MemberLeaderboardDashFormat }
export type ByMembersBrand = { [retailerID: string]: MemberLeaderboardEntry[] }
export type ByMembersByDayBrand = { [retailerID: string]: { [memberID: string]: { [date: string]: MemberLeaderboardEntry } } }
export type ByMembersByGoalBrand = { [retailerID: string]: { [memberID: string]: MemberGoalBreakdown } }
export type MemberByBrandAndProductBrand = { [retailerID: string]: { [brandID: string]: { [productID: string]: MemberLeaderboardEntry } } }
export type MemberByCategoryAndProductBrand = { [retailerID: string]: { [categoryID: string]: { [productID: string]: MemberLeaderboardEntry } } }

// NOTE BY LOCATIONS ---> BRAND

export type LocationLeaderboardDashFormat = LocationLeaderboardEntry & {
    byUID: { [uid: string]: LocationLeaderboardEntry }
}
export type ByLocationsBrandMap = { [locationID: string]: LocationLeaderboardDashFormat }
export type ByLocationBrand = { [retailerID: string]: LocationLeaderboardEntry[] }
export type ByLocationByDayBrand = { [retailerID: string]: { [locationID: string]: { [date: string]: LocationLeaderboardEntry } } }
export type ByLocationByGoalBrand = { [retailerID: string]: { [locationID: string]: LocationGoalBreakdown } }
export type LocationByBrandAndProductBrand = { [retailerID: string]: { [brandID: string]: { [productID: string]: LocationLeaderboardEntry } } }
export type LocationByCategoryAndProductBrand = { [retailerID: string]: { [categoryID: string]: { [productID: string]: LocationLeaderboardEntry } } }

// NOTE BY GOAL ---> BRAND

export type GoalLeaderboardDashFormat = GoalLeaderboardEntry & {
    byUID: { [uid: string]: GoalLeaderboardEntry }
}
export type ByGoalBrandMap = { [goalID: string]: GoalLeaderboardDashFormat }
export type ByGoalBrand = { [retailerID: string]: GoalLeaderboardWithRollup }
export type ByGoalByDayBrand = { [retailerID: string]: { [goalID: string]: { [date: string]: GoalLeaderboardEntry } } }
export type GoalByBrandAndProductBrand = { [retailerID: string]: { [brandID: string]: { [productID: string]: GoalLeaderboardEntry } } }
export type GoalByCategoryAndProductBrand = { [retailerID: string]: { [categoryID: string]: { [productID: string]: GoalLeaderboardEntry } } }

export type ProductSaleEntry = FormattedSalesMetric & {
    brand?: string,
    category?: string,
}

export type BrandSaleEntry = FormattedSalesMetric & {
    products: ProductSaleEntry[]
}

export type CategorySaleEntry = FormattedSalesMetric & {
    products: ProductSaleEntry[]
}

export type SalesData = {
    productSalesByBrand: BrandSaleEntry[],
    productSalesByCategory: CategorySaleEntry[],
    allProductSales: ProductSaleEntry[],
}

export type SnapshotData = {
    global?: GlobalSnapshot,
    members?: MemberSnapshot,
    goals?: GoalSnapshot,
    locations?: LocationSnapshot
}

export type GlobalSnapshot = {
    totalRevenue: number
    goalsRevenue: number
    goalsRevenuePercentage: number
    orders: number
    acv: number
}

export type MemberSnapshot = {
    topMembers: MemberLeaderboardEntry[]
    topMembersByDay: { [memberID: string]: { [date: string]: MemberLeaderboardEntry } }
}

export type LocationSnapshot = {
    topLocations: LocationLeaderboardEntry[]
    topLocationsByDay: { [locationID: string]: { [date: string]: LocationLeaderboardEntry } }
    topLocationsByMember: { [memberID: string]: MemberLeaderboardEntry[] }
}

export type GoalSnapshot = {
    topGoals: GoalLeaderboardEntryWithPercentage[]
    topGoalsByDay: { [goalID: string]: { [date: string]: GoalLeaderboardEntry } }
    topGoalsByMember: { [memberID: string]: MemberLeaderboardEntry[] }
}

export const getRandomEntry = (key: number, targetID = utils.getUUID()): FormattedSalesMetric => ({
    key,
    id: targetID,
    acv: Math.random() * 10,
    totalProducts: Math.random() * 100,
    name: `${generateRandomWord()} ${generateRandomWord()}`,
    revenue: Math.random() * 1000,
    transactions: Math.random() * 100,
    units: Math.random() * 100,
})

// Mock MemberByBrandAndProductBrand
export const getMockMemberByBrandAndProductBrand = (): MemberByBrandAndProductBrand => {
    return {
        "1048": {
            [generateRandomString()]: {
                [generateRandomString()]: getRandomEntry(0),
                [generateRandomString()]: getRandomEntry(0),
                [generateRandomString()]: getRandomEntry(0),
                [generateRandomString()]: getRandomEntry(0),
            },
            [generateRandomString()]: {
                [generateRandomString()]: getRandomEntry(0),
                [generateRandomString()]: getRandomEntry(0),
                [generateRandomString()]: getRandomEntry(0)
            },
            [generateRandomString()]: {
                [generateRandomString()]: getRandomEntry(0)
            },
        }
    }
}

export const getMockGoalByBrandAndProduct = (): GoalByBrandAndProduct => {
    return {
        [generateRandomString()]: {
            [generateRandomString()]: getRandomEntry(0),
            [generateRandomString()]: getRandomEntry(0),
            [generateRandomString()]: getRandomEntry(0),
            [generateRandomString()]: getRandomEntry(0),
        },
        [generateRandomString()]: {
            [generateRandomString()]: getRandomEntry(0),
            [generateRandomString()]: getRandomEntry(0),
            [generateRandomString()]: getRandomEntry(0)
        },
        [generateRandomString()]: {
            [generateRandomString()]: getRandomEntry(0)
        },
    }
}

// Get a date that is today minus -x days, YYYY-MM-DD
export const getMockDate = (days: number): string => {
    const today = new Date();
    const date = new Date(today.setDate(today.getDate() - days));
    return date.toISOString().split('T')[0];
}

// Mock Snapshot data
export const getMockSnapshotData = (): SnapshotData => {

    const topMembers: MemberLeaderboardEntry[] = Array.from({ length: 10 }, (_, i) => getRandomEntry(i))
    const topGoals: GoalLeaderboardEntryWithPercentage[] = Array.from({ length: 10 }, (_, i) => getRandomEntry(i)) as GoalLeaderboardEntryWithPercentage[]
    const topLocations: LocationLeaderboardEntry[] = Array.from({ length: 10 }, (_, i) => getRandomEntry(i))

    return {
        global: {
            totalRevenue: Math.random() * 1000,
            goalsRevenue: Math.random() * 1000,
            goalsRevenuePercentage: Math.random() * 100,
            orders: Math.random() * 100,
            acv: Math.random() * 1000
        },
        members: {
            topMembers,
            topMembersByDay: topMembers.reduce((prev, entry) => {
                const byDate: { [date: string]: MemberLeaderboardEntry } = Array.from({ length: 10 }, (_, i) => getRandomEntry(i)).reduce((prev, entry, i) => {
                    return {
                        ...prev,
                        [getMockDate(i)]: entry
                    }
                }, {})
                return {
                    ...prev,
                    [entry.id]: byDate
                }
            }, {}),
        },
        goals: {
            topGoals: topGoals,
            topGoalsByDay: topGoals.reduce((prev, entry) => {
                const byDate: { [date: string]: GoalLeaderboardEntry } = Array.from({ length: 10 }, (_, i) => getRandomEntry(i)).reduce((prev, entry, i) => {
                    return {
                        ...prev,
                        [getMockDate(i)]: entry
                    }
                }, {})
                return {
                    ...prev,
                    [entry.id]: byDate
                }
            }, {}),
            topGoalsByMember: topGoals.reduce((prev, entry) => {
                const byMember: MemberLeaderboardEntry[] = Array.from({ length: 10 }, (_, i) => getRandomEntry(i))
                return {
                    ...prev,
                    [entry.id]: byMember
                }
            }, {}),
        },
        locations: {
            topLocations: topLocations,
            topLocationsByDay: topLocations.reduce((prev, entry) => {
                const byDate: { [date: string]: LocationLeaderboardEntry } = Array.from({ length: 10 }, (_, i) => getRandomEntry(i)).reduce((prev, entry, i) => {
                    return {
                        ...prev,
                        [getMockDate(i)]: entry
                    }
                }, {})
                return {
                    ...prev,
                    [entry.id]: byDate
                }
            }, {}),
            topLocationsByMember: topLocations.reduce((prev, entry) => {
                const byMember: MemberLeaderboardEntry[] = Array.from({ length: 10 }, (_, i) => getRandomEntry(i))
                return {
                    ...prev,
                    [entry.id]: byMember
                }
            }, {}),
        }
    }
}